import { RColumn, Title1 } from "./compnents/otherCOmps";
import { Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./pages/homePgae";
import VerifyEmailPage from "./pages/authPage";
import LoginPage from "./pages/loginPage";
import DashboardPage from "./pages/dashboard";
import OnboardingPage from "./pages/onboardingPage";
import { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import Header from "./pages/header";
import { ToastContainer } from "react-toastify";
import logoImg from "./assets/LOGO_nero.png";
import ModifyProfilePage from "./pages/modifyProfilePage";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { BaseData, ColorPalette } from "./util/const";
import { LoadingComp } from "./compnents/loadingComp";
import { doc, getDoc } from "firebase/firestore";

const StartingPage = () => {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUserm) => {
      setIsLoading(false);
      if (authUserm) {
        setIsLogged(true);
        await getPrimData();
        if (authUserm.emailVerified === false) {
          navigate("/verifyEmail");
        } else {
          setDashboard(true);
          navigate("/");
        }
      } else {
        setIsLogged(false);
        setDashboard(false);
        navigate("/");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  async function getPrimData() {
    const baseInfoDocRef = doc(db, "info/base");
    const baseInfoDoc = await getDoc(baseInfoDocRef);

    const categorie = baseInfoDoc.data().categorie;
    let catOpt = [];
    let subCatOpt = {};
    Object.keys(categorie).forEach((key) => {
      catOpt.push({ value: key, label: categorie[key].label });

      subCatOpt[key] = categorie[key].sub;
    });
    BaseData.categorieOptions = catOpt;
    BaseData.sottoCategorieOptions = subCatOpt;
    BaseData.competenzeOptions = baseInfoDoc.data().competenze;
    BaseData.generiOptions = baseInfoDoc
      .data()
      .generi.map((genere) => ({ value: genere, label: genere }));
    BaseData.typeCallLabel = baseInfoDoc.data().typeCallLabel;
  }

  if (loading) {
    return (
      <RColumn
        height={"90vh"}
        width={"100%"}
        horizontalAlign="center"
        verticalAlignment="center"
      >
        <LoadingComp />
      </RColumn>
    );
  }
  return (
    <>
      <CookieConsent
        buttonText="Capito!"
        buttonStyle={{
          backgroundColor: ColorPalette.accentOrange,
          color: "white",
        }}
      >
        Questo sito web utilizza i cookie per migliorare l'esperienza
        dell'utente.
      </CookieConsent>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <Header isLogged={isLogged} />
      <Routes>
        <Route
          path="/"
          element={dashboard ? <DashboardPage /> : <HomePage />}
        />
        <Route exact path="/verifyEmail" element={<VerifyEmailPage />} />
        <Route exact path="/accedi" element={<LoginPage />} />
        {/*  <Route exact path="/dashboard" element={<DashboardPage/>} /> */}
        <Route exact path="/onboarding" element={<OnboardingPage />} />
        <Route exact path="/modify" element={<ModifyProfilePage />} />
      </Routes>
    </>
  );
};

export default StartingPage;
