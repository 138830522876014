import { BaseData, ColorPalette, prefLinkSocial, socialIDToImg, socialLogos } from "../util/const"
import { useTheme } from "../util/theme"
import { PrimaryBtn, RColumn, RGrid, RRow, RText, Space, SpaceHorizontal, Title2 } from "./otherCOmps"
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
export const SingleCallProb = ({data}) => {
    const {isMobile} = useTheme();


    function SingleHistoryData({data}){
       if(data.type === "request_change_date"){

        let datProp = "";
        data.new_dates.forEach((d, i) => {
            datProp += format(Date.parse(d), "dd/MM HH:mm", {locale: it});
            if(i < data.new_dates.length - 1)
                datProp += " • ";
        });
        return(
            <>
            <RText>{data.msg}</RText>
            <RText>Date proposte: {datProp}</RText>
            
            </>
        )
       }
    }
    return (
        <div style={{border: "2.5px solid " + ColorPalette.primaryColor, borderRadius: "12px", width: "100%", marginBottom: "8px"}}>
            <div style={{padding: "16px"}}>
        
        <RText>Stato: <strong>{BaseData.typeCallLabel[data.state]}</strong></RText>
        <Space/>
        <RRow verticalAlignment="start">
            <RColumn width={"50%"} horizontalAlign="start">
                {(data.history ?? []).map((h, i) => (
                    <div key={i} style={{width: "100%", backgroundColor: ColorPalette.bgColorLight, marginBottom: "9px", borderRadius: "6px", padding: "6px"}}>
                    <RRow horizontalAlign="space-between">
                        <RText>{format(new Date(h.date), "EEE d MMM HH:mm", {locale: it})}</RText>
                        <SpaceHorizontal/>
                        <RText color={ColorPalette.accentOrange}>{h.from}</RText>
                        <SpaceHorizontal/>
                        <RText fontWeight="bold">{BaseData.typeCallLabel[h.type]}</RText>
                    </RRow>
                    <SingleHistoryData data={h}/>
                    </div>
                ))}
            </RColumn>

            <RColumn width={"50%"} verticalAlignment="start">
                <RRow verticalAlignment="start">
                    <img  style={{objectFit: 'cover',borderRadius: "100%"}} width={"70px"} height={"70px"} src={data.user?.profilePicLink ?? ""}/>
                    <SpaceHorizontal/>
                    <RColumn horizontalAlign="start">
                        <RRow>
                            <Title2 size="18px">{data.user?.name ?? "--"}</Title2>
                            <SpaceHorizontal/>
                            <RText>{data.user?.tipoArtista ?? ""}</RText>
                        </RRow>
                        <RRow>
                            {(data.user?.generi ?? []).map((gen, ind) =>(
                                <div key={ind} style={{marginRight: "6px"}}>
                                <RText >{gen}</RText>
                                </div>
                            ))}
                        </RRow>
                        <Space height="6px"/>
                        <RText>{data.user?.bio}</RText>
                    </RColumn>
                </RRow>
                <RRow>
                {Object.values(data.user?.socials ?? {}).map((soc, ind) => (
                    <a key={ind} href={prefLinkSocial[soc.id] + soc.username} target="_blank">
                        <img style={{ padding: "0px 8px" }} key={ind} src={socialIDToImg[soc.id]} width="24px"/>
                    </a>
                ))}
                </RRow>
            </RColumn>
            </RRow>
       
            <Space/>
          
            </div>
        </div>
    )
}