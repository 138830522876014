import { RColumn, Space, Title2 } from "./otherCOmps";
import logoImgBlack from "../assets/LOGO_nero.png"
import logoImgWhite from "../assets/LOGO_bianco.png";
import { useTheme } from "../util/theme";
import { ColorPalette } from "../util/const";

export function LoadingComp(){
    const {isDarkMode} = useTheme();
    return(
        <img src={isDarkMode ? logoImgWhite : logoImgBlack} width={"200px"}/>
    )
}

export function LoadingModal({text = "Carico..."}){
    const {isDarkMode} = useTheme();
    return(
        <div style={{zIndex: 10,height: "100vh", width: "100%", display: "flex", position: "fixed", backgroundColor: "rgba(0,0,0,0.5)", alignItems: "center", justifyContent: "center"}}>
            <div style={{borderRadius: "12px", width: "100%", maxWidth: "300px", height: "200px", backgroundColor: isDarkMode ? ColorPalette.bgColorDark : ColorPalette.bgColorLight, display: "flex", flexDirection: "column",alignItems: "center", justifyContent: "center"}}>
                <Title2>{text}</Title2>
                <Space/>
                <LoadingComp/>
            </div>
        </div>
    )
}