import { addDays, setHours, setMinutes } from "date-fns";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

export default function RDateTimePicker({onDateSelected, datesNotAvaiable = [] , startDate = setHours(setMinutes(addDays(new Date(), 1), 0), 10), endDate}){

    const [selectedDate, setSelectedDate] = useState(startDate);

    const filterDate = (date) => {
        return !datesNotAvaiable.some(disabledDate => {
            return (
                date.getDate() === disabledDate.getDate() &&
                date.getMonth() === disabledDate.getMonth() &&
                date.getFullYear() === disabledDate.getFullYear() &&
                date.getHours() === disabledDate.getHours() &&
                date.getMinutes() === disabledDate.getMinutes()
            );
        });
    }   
    function handleChange(date){
        setSelectedDate(date);
        onDateSelected(date);
    }

    return(
        
        <ReactDatePicker
            fixedHeight
            selected={selectedDate}
            onChange={handleChange}
            minDate={addDays(new Date(), 1)}
            maxDate={addDays(endDate, 7)}
            minTime={setHours(setMinutes(new Date(), 0), 8)}
            maxTime={setHours(setMinutes(new Date(), 0), 22)}
            filterTime={filterDate}
            showTimeSelect
            inline
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat="MMMM d, yyyy h:mm aa"
            />
    )
}