import { useEffect, useState } from "react";
import { collection, deleteField, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { analytics, auth, db, functions, storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import { BlackButton, DropDownMenu, FileModButton, FileUploadButtonWhite, InputFieldMultiline, ModifyBtn, PrimaryBtn, RColumn, RGrid, RRow, RText, SecondaryBtn, Space, SpaceHorizontal, Title1, Title2 } from "../compnents/otherCOmps"
import { BaseData, ColorPalette, yearOptions } from "../util/const";
import { useTheme } from "../util/theme";
import { SelezionaGiorniOrai } from "../compnents/selectOrari";
import { showErrorNotification, showSuccessNotification } from "../compnents/errorMessagesComp";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { SIngleChiamata } from "../compnents/SingleChiamata";
import { addDays, format } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { logEvent } from "firebase/analytics";
import RDateTimePicker from "../compnents/selectDataOraComp";
import { it } from 'date-fns/locale';
import { SingleCallProb } from "../compnents/singleCallProb";
const DashboardPage = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const {isDarkMode, isMobile} = useTheme();
    
    const [nome, setNome] = useState("");
    const [anniEsp, setAnniEsp] = useState("");
    const [city, setCity] = useState("");
    const [dataNascita, setDataNascita] = useState();

    const [categoria, setCategoria] = useState();
    const [sottoCategoria, setSottoCategoria] = useState();
    const [azienda, setAzienda] = useState();

    
    const [profilePicPrev, setProfilePicPrev] = useState(null);
    const [copertinaPicPrev, setCopertinaPicPrev] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [selectedDisp, setSelectedDisp] = useState();

    const [descrizione, setDescrizione] = useState("");
    const [generi, setGeneri] = useState();
    const [linkSocial, setLinkSocial] = useState();


    const [isModifingOrari, setIsModOrari] = useState(false);
    const [isModCompetenze, setIsModCompetenze] = useState(false);
    const [cancellaCall, setCancellaCall] = useState({open: false, id: "", msg:""})
    const [riprogrammaCall, setRiprogrammaCall] = useState({open: false, id: "", msg:"", callDoc: {}})

    const [competenze, setCompetenze] = useState([]);
    const [futureCall, setFutureCall] = useState([]);

    const [callRimandateAttesa, setCallRimandateAttesa] = useState([]);

    const [selectedRiprogramDates, setSelectedRiprogramDates] = useState([null, null, null]);
    const [selectedRiprogramDatesIndex, setSelectedRiprogramDatesIndex] = useState(0);

    const [disp_block_date, setDisp_block_date] = useState();
    const [isBlockedDisp, setIsBlockedDisp] = useState(false);

    useEffect(() => {
        if(auth.currentUser)
            getData();
    }, []);

    async  function getData(){
        try{
        const ref = doc(db, "mentors", auth.currentUser.uid);
        const userDoc = await getDoc(ref);
        if(!userDoc.exists() || userDoc.data()["approvato"] !== true){
            //prima volta
            navigate("/onboarding");
            return;
        }
        if(userDoc.data()["disp_block_date"]){
            setDisp_block_date(userDoc.data()["disp_block_date"]);
            let now = Date.now();
            const sevenDaysAgo = now - (7 * 24 * 60 * 60 * 1000);
            const isBlock = userDoc.data()["disp_block_date"] > sevenDaysAgo;
            setIsBlockedDisp(isBlock);
        }

        setNome(userDoc.data()["nome"] ?? "");
        setAnniEsp(userDoc.data()["anni_esperienza"] ?? "");
        setCity(userDoc.data()["city"] ?? "");
        setDataNascita(yearOptions.find(c => c.value === userDoc.data()["annoNascita"]))

        setCategoria(BaseData.categorieOptions.find(c => c.value === userDoc.data()["categoria"]));
        setSottoCategoria((BaseData.sottoCategorieOptions[userDoc.data()["categoria"]] ?? []).filter(c =>  (userDoc.data()["sotto_categoria"] ?? []).includes(c.value)));
        setAzienda(userDoc.data()["azienda"]);

        setProfilePicPrev(userDoc.data()["profilePicLink"]);
        setCopertinaPicPrev(userDoc.data()["copertinaPicLink"]);

        setIsSubmitted(userDoc.data()["isSubmitted"] ?? false);

        setSelectedDisp( Object.fromEntries(Object.entries(userDoc.data()["disp"]).sort()));

        setDescrizione(userDoc.data()["desc"]);
        setGeneri(BaseData.generiOptions.filter(c => (userDoc.data()["generi"] ?? []).includes(c.value)));
        setLinkSocial(userDoc.data()["linkSocial"]);

        setCompetenze(BaseData.competenzeOptions.filter(c => (userDoc.data()["competenze"] ?? []).includes(c.value)));

        setIsLoading(false);

        loadCalls();
       
    }catch(e){
        console.log(e);
    }
    }

    async function loadCalls(){
        const myFutureCallRef = collection(db,"appuntamentiMentor");
        const q = query(myFutureCallRef, 
            where("uidMentor", "==", auth.currentUser.uid),
            where("state", "==", "active"),
            where("data", ">=", format(new Date(), "yyyy-MM-dd")),
            orderBy("data", "desc"),
        )
        const callSnaps = await getDocs(q);
        let futCall = [];

        callSnaps.docs.forEach((call) => {
            futCall.push({...call.data(), id: call.id});
        });

        setFutureCall(futCall);

        //rimandate
        const qRimandate = query(myFutureCallRef, 
            where("uidMentor", "==", auth.currentUser.uid),
            where("state", "==", "waiting_change_date"),
            where("data", ">=", format(new Date(), "yyyy-MM-dd")),
            orderBy("data", "desc"),
        )
        const callSnapsRimandate = await getDocs(qRimandate);
        let futCallR = [];
        callSnapsRimandate.docs.forEach((call) => {
            futCallR.push({...call.data(), id: call.id});
        });
        console.log(futCallR);
        setCallRimandateAttesa(futCallR);
    }

    
    const onImageChangeProfile = (file) => {
        if (file) {
          setProfilePicPrev(URL.createObjectURL(file));
          setProfilePic(URL.createObjectURL(file));
        }else{
            showErrorNotification("Nessun file selezionato");
        }
    }
    async function setProfilePic(newPic){
        try{
            const refM = doc(db, "mentors", auth.currentUser.uid);
            const storageRefProfile = ref(storage, "mentors/" + auth.currentUser.uid+"/images/profilePic");
            const imageBlobProfile = await fetch(newPic).then((response) => response.blob());
            await uploadBytes(storageRefProfile, imageBlobProfile);
            const profUrl = await getDownloadURL(storageRefProfile);
            await updateDoc(refM, {
                "profilePicLink": profUrl,
            });
            showSuccessNotification("Immagine profilo caricata!");
        }catch(e){
            showErrorNotification("Errore nel caricare immagine di profilo");
        }
    }
    const onImageChangeCopertina = (file) => {
        if (file) {
          setCopertinaPicPrev(URL.createObjectURL(file));
          setCopertinaPic(URL.createObjectURL(file));
        }else{
            showErrorNotification("Nessun file selezionato");
        }
    }
    async function setCopertinaPic(newPic){
        try{
            const refM = doc(db, "mentors", auth.currentUser.uid);
            const storageRefProfile = ref(storage, "mentors/" + auth.currentUser.uid+"/images/copertina");
            const imageBlobProfile = await fetch(newPic).then((response) => response.blob());
            await uploadBytes(storageRefProfile, imageBlobProfile);
            const profUrl = await getDownloadURL(storageRefProfile);
            await updateDoc(refM, {
                "copertinaPicLink": profUrl,
            });
            showSuccessNotification("Immagine di copertina caricata!");
        }catch(e){
            showErrorNotification("Errore nel caricare immagine di copertina");
        }
    }

    async function saveOraiDisp(){
        setIsModOrari(false);
        const newMentorRef = doc(db, "mentors", auth.currentUser.uid);
        try {
            await updateDoc(newMentorRef, {"disp": selectedDisp});
            showSuccessNotification("Orari modificati!");
        } catch (error) {
            showErrorNotification("Errore nel modificare gli orari");
        }
    }
    async function saveCompetenze(){
        setIsModCompetenze(false);
        const newMentorRef = doc(db, "mentors", auth.currentUser.uid);
        try {
            await updateDoc(newMentorRef, {"competenze": competenze.map(c => c.value)});
            showSuccessNotification("Competenze modificate!");
        } catch (error) {
            showErrorNotification("Errore nel modificare le competenze");
        }
    }
    async function eliminaCall(id, msg){
        setIsLoading(true);
        const funct = httpsCallable(functions, "eliminaCall");
        const dat = {
            callID: id,
            msg: msg,
        }
        try {
            await funct(dat);
            showSuccessNotification("Call eliminata");
            logEvent(analytics, "delete_call_mentor");
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            showErrorNotification("Errore nell'eliminare la call");
            return;
        }
        setCancellaCall({open: false, id: "", msg: ""});
        loadCalls();
        setIsLoading(false);
    }

    if(isLoading){
        return <>Loading</>
    }
    function SingleCompetenza({name}){
        return(
            <div style={{padding: "8px 12px", marginRight: "8px", marginBottom: "8px", border: "2px solid " + ColorPalette.primaryColor, borderRadius: "50px"}}>
               <RText size="16px">{name}</RText>
            </div>
        )
    }
    function SingleSezione({children}){
        return(
            <div style={{padding: isMobile ? "0px 0px": "0px 50px", display:"flex", justifyContent:"center"}}>
            <div style={{ overflow: "hidden", borderRadius: "14px", width: "100%", maxWidth: "1200px", backgroundColor: isMobile ? "": (isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight)}}>
            <div style={{padding: "0px 20px"}}>
                {children}
            </div>
            </div>
            </div>
        )
    }

    function closeRiprogrammCall(){
        setRiprogrammaCall({open: false, id: "", msg: "", callDoc: {}});
        setSelectedRiprogramDates([null,null,null]);
        setSelectedRiprogramDatesIndex(0);
    }

    async function runRiprogrammaCall(id, msg, newOrari){

        if(!newOrari){
            showErrorNotification("Non hai selezionato orari");
            return;
        }
        if(newOrari.some(d => d === null)){
            showErrorNotification("Seleziona 3 date");
            return;
        }
        setIsLoading(true);
        const newOrariStr = newOrari.map(c => format(c, "yyyy-MM-dd HH:mm"));

        const funct = httpsCallable(functions, "riprogrammaCall");
        const dat = {
            callID: id,
            msg: msg,
            newDates: newOrariStr,
        }
        try {
            await funct(dat);
            showSuccessNotification("Richiesta mandata");
            logEvent(analytics, "req_chg_date_call_mntr");
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            showErrorNotification("Errore nel cambiare la call");
            return;
        }
        setRiprogrammaCall({open: false, id: "", msg: "", callDoc:{}});
        loadCalls();
        setIsLoading(false);
    }

    async function bloccaSbloccaDisp(){
       
        const isBlock = !isBlockedDisp;
        const newD = {
          //  "disp_state": newState,
            "disp_block_date": isBlock ? Date.now() : deleteField(),
        }
        const ref = doc(db, "mentors", auth.currentUser.uid);
        await updateDoc(ref, newD);
        setIsBlockedDisp(isBlock);
        setDisp_block_date(Date.now());
    }

    return(
        <>
        {isModifingOrari && <>
        <div onClick={() => setIsModOrari(false)} style={{ position: "fixed", width: "100%", height:"100vh", backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: 3, display:"flex", justifyContent:"center", alignItems: "center"}}>
        <div onClick={(e) => e.stopPropagation()} style={{ overflow: "hidden", borderRadius: "14px", margin: "10px", width: "100%", maxWidth: "700px", backgroundColor: (isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight)}}>
            <RColumn>
            <Space/>
            <Title2 align={"center"}>Disponibilità</Title2>
            <Space/>
            <Space/>
            <SelezionaGiorniOrai width={isMobile ? "95%" : "500px"} selectDispCallback={(v) => setSelectedDisp(v)} initialSelectedDisp={selectedDisp}/>
            <Space height="12px"/>
           
                <PrimaryBtn onClick={saveOraiDisp}>Salva</PrimaryBtn>
            </RColumn>
            <Space/>
           
        </div>
        </div>
        </>
        }
         {isModCompetenze && <>
        <div onClick={() => setIsModCompetenze(false)} style={{ bottom: "0px",position: "fixed", width: "100%", height:"100vh", backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: 3, display:"flex", justifyContent:"center", alignItems: "center"}}>
        <div onClick={(e) => e.stopPropagation()} style={{ borderRadius: "14px", margin: "10px", width: "100%", maxWidth: "700px", backgroundColor: (isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight)}}>
         <RColumn al>
            <Space/>
            <Title2 align={"center"}>Competenze</Title2>
            <Space/>
            <Space/>
            <DropDownMenu isMulti={true} options={BaseData.competenzeOptions} placeHolder={"Competenze"} onChange={(comps) => {if(comps.length > 8) {showErrorNotification("Massimo di 8 competenze superato"); return;} setCompetenze(comps)}} selectedValue={competenze} width={isMobile ? window.innerWidth - 60: "660px"}/>
            <Space height="12px"/>
           
                <PrimaryBtn onClick={saveCompetenze}>Salva</PrimaryBtn>
            </RColumn>
            <Space/>
           
        </div>
        </div>
        </>
        }
         {cancellaCall.open && <>
        <div onClick={() => setCancellaCall({open: false, id: "", msg: ""})} style={{ bottom: "0px",position: "fixed", width: "100%", height:"100vh", backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: 3, display:"flex", justifyContent:"center", alignItems: "center"}}>
        <div onClick={(e) => e.stopPropagation()} style={{ borderRadius: "14px", margin: "10px", width: "100%", maxWidth: "700px", backgroundColor: (isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight)}}>
         <RColumn padding={"0px 12px"}>
            <Space/>
            <Title2 align={"center"}>Eliminare la call?</Title2>
            <Space/>
            <InputFieldMultiline placeHolder={"Spiega il motivo all'artista"} setValue={(v) => setCancellaCall((prev) => ({...prev, msg: v}))} value={cancellaCall.msg ?? ""} numRighe={3}/>
            <Space/>
            <Space height="12px"/>
            <RRow>
                <div style={{cursor: "pointer"}} onClick={() => setCancellaCall({open: false, id: "", msg: ""})}><RText>Chiudi</RText></div>
                <SpaceHorizontal width="22px"/>
                <PrimaryBtn onClick={()=>eliminaCall(cancellaCall.id, cancellaCall.msg)}>Elimina</PrimaryBtn>
            </RRow>
           
            </RColumn>
            <Space/>
           
        </div>
        </div>
        </>
        }
        {riprogrammaCall.open && <>
        <div onClick={closeRiprogrammCall} style={{ bottom: "0px",position: "fixed", width: "100%", height:"100vh", backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: 3, display:"flex", justifyContent:"center", alignItems: "center"}}>
        <div onClick={(e) => e.stopPropagation()} style={{ borderRadius: "14px", margin: "10px", width: "100%", maxWidth: "700px", backgroundColor: (isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight)}}>
         <RColumn padding={"0px 12px"}>
            <Space/>
            <Title2 align={"center"}>Riprogramma</Title2>
            <Space height="10px"/>
            <RText>Seleziona 3 date da proporre all'artista</RText>
            <Space/>
            <RRow>
                <RDateTimePicker datesNotAvaiable={futureCall.map(c => {
                    const [anno, mese, giorno] = c.data.split('-').map(Number);
                    const [ora, minuti] = c.ora.split(':').map(Number);
                    return new Date(anno, mese - 1, giorno, ora, minuti);
                }).concat(selectedRiprogramDates.filter(d => d !== null))} onDateSelected={(d) =>  setSelectedRiprogramDates(prevArray => {
                    const newArray = [...prevArray];
                    newArray[selectedRiprogramDatesIndex] = d;
                    return newArray;
                  })} endDate={Date.parse(riprogrammaCall.callDoc.data)}/>
                <SpaceHorizontal/>
                <RColumn>
                {Array.from({ length: 3 }, (_, index) => (
                    <div key={index}
                        onClick={() => setSelectedRiprogramDatesIndex(index)}
                        style={{margin: "4px 0px", cursor: "pointer",width: "150px",textAlign: "center",padding: "4px", border: index === selectedRiprogramDatesIndex ? "solid 3px " + ColorPalette.accentGreen : "solid 3px transparent", borderRadius: "8px"}}
                    >    
                    {selectedRiprogramDates[index] ? format(selectedRiprogramDates[index], 'EEE d MMM HH:mm', { locale: it }) : "Seleziona data"}
                    </div>
                ))}
                   
                </RColumn>
            </RRow>
          
            <Space/>
            <InputFieldMultiline placeHolder={"Spiega il motivo all'artista"} setValue={(v) => setRiprogrammaCall((prev) => ({...prev, msg: v}))} value={riprogrammaCall.msg ?? ""} numRighe={3}/>
            <Space/>
            <Space height="12px"/>
            <RRow>
                <div style={{cursor: "pointer"}} onClick={closeRiprogrammCall}><RText>Chiudi</RText></div>
                <SpaceHorizontal width="22px"/>
                <PrimaryBtn onClick={()=>runRiprogrammaCall(riprogrammaCall.id, riprogrammaCall.msg, selectedRiprogramDates)}>Manda preferenza</PrimaryBtn>
            </RRow>
           
            </RColumn>
            <Space/>
           
        </div>
        </div>
        </>
        }
       
        <Space height="100px"/>
        <div style={{padding: isMobile ? "0px 0px": "0px 50px", display:"flex", justifyContent:"center"}}>
            <div style={{ overflow: "hidden", borderRadius: "14px", width: "100%", maxWidth: "1200px", backgroundColor: isMobile ? "": (isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight)}}>
            <div style={{position: "relative"}}>
                <img src={copertinaPicPrev ?? "https://mentor.hatmusic.it/banners/banner1.jpg"} width={"100%"} height={"150px"} style={{objectFit: "cover"}} />
                <div style={{ position: 'absolute', width: '120px', height: '120px', left: '20px',bottom: '-40px' }}>
                <img
                    src={profilePicPrev}
                    alt="Profile Pic"
                    width={"120px"}
                    height={"120px"}
                    style={{
                    objectFit: 'cover',
                    borderRadius: '10px',
                    }}
                />
                <FileModButton onFileChange={onImageChangeProfile}/>
                </div>
            {/* <div style={{position: "absolute", top: "12px", right: "12px"}}><BlackButton onClick={() => navigate("/modify")}>Modifica informazioni</BlackButton></div> */}
            <div style={{position: "absolute", top: "12px", right: copertinaPicPrev ? "22px" : "12px"}}>
            {copertinaPicPrev && <FileModButton onFileChange={onImageChangeCopertina}/>}
            {!copertinaPicPrev && <FileUploadButtonWhite onFileChange={onImageChangeCopertina} text="Carica copertina"/>}
            </div>
            </div>
            <Space height="60px"/>
            <div style={{padding: "0px 20px"}}>
            <RGrid verticalAlignment="start" horizontalAlign={isMobile ? "start" : "space-between"}>
                <RColumn horizontalAlign="start" flex={isMobile ? null : 1} width={isMobile ? "100%" : null} padding={isMobile ? "0px 0px 24px 0px": null}>
                    <RRow>
                        <Title2>{nome}</Title2>
                        <SpaceHorizontal width="12px"/>
                        <div style={{backgroundColor: "#6bab6f71", padding: "4px 6px", borderRadius: "6px"}}>
                            <RText size="16px" color={"white"}>{categoria.label}</RText>
                        </div>
                    </RRow>
                    <Space height="12px"/>
                    <RRow>
                        <RText>Esperto nel settore da </RText>
                        <SpaceHorizontal/>
                        <Title2 size="19px">{anniEsp !== 11 ? anniEsp : "10+"} {anniEsp === 1 ? "anno" : "anni"}</Title2>

                    </RRow>
                    <Space height="12px"/>
                    <RText>{(new Date().getFullYear() - dataNascita.value)} anni</RText>
                    <Space height="12px"/>
                    <RRow>
                        {sottoCategoria.map((element, index) => (
                            <div key={index} style={{marginRight: "8px"}}>
                                <RText>{element.label}</RText>
                            </div>
                        ))}
                    </RRow>
                    <Space height="12px"/>
                    <RText>{city}</RText>

                </RColumn>
               
                <RColumn flex={isMobile ? null : 1} width={isMobile ? "100%" : null} horizontalAlign={isMobile ? "start" : "center"} padding={isMobile ? "0px 0px 24px 0px": null}>
                    <RRow verticalAlignment="start">
                        {!isMobile && 
                        <>
                        <ModifyBtn padding={"8px 2px"} onClick={()=>setIsModOrari(true)}/>
                        <SpaceHorizontal/>
                        </> }
                        <RColumn>
                            <div style={{opacity: isBlockedDisp ? 0.3 : null}}>
                                {Object.entries(selectedDisp).map(([key, value]) => (
                                <RRow key={key} padding={"3px 0px"} verticalAlignment="start">
                                    <div style={{width: "55px"}}>
                                        <RText fontWeight="bold" size="17px">{value.giorno.slice(0,3)}</RText>
                                    </div>
                                    <RColumn horizontalAlign="start">
                                        {value.orari.map((o, index) => (
                                            <RText key={index} size="15px">{o.start} - {o.end}</RText>
                                        ))}
                                    </RColumn>
                                </RRow>
                                
                            ))}
                            </div>
                        <Space/>
                        {isBlockedDisp && <>
                        <RText align={"center"} size="15px">Non puoi ricevere call fin quando non riattivi la disponibilità.</RText><Space height="4px"/>
                      
                        <RText size="14px">{`Si attiva in ${Math.max(0, 7 - Math.floor((Date.now() - disp_block_date) / (24 * 60 * 60 * 1000)))} giorni.`}</RText>
                        <Space height="6px"/>
                        </>}
                        <BlackButton onClick={bloccaSbloccaDisp}>{isBlockedDisp ? "Attiva Disponibilità" :"Blocca Disponibilità"}</BlackButton>
                        </RColumn>
                      
                        {isMobile && 
                        <>
                        <SpaceHorizontal/>
                        <ModifyBtn padding={"8px 2px"} onClick={()=>setIsModOrari(true)}/>
                        </> }
                    </RRow>
                </RColumn>
                <RColumn flex={isMobile ? null : 1} width={isMobile ? "100%" : null} horizontalAlign={isMobile ? "start" : "end"}>
                    <RColumn horizontalAlign="start">
                        {azienda.map((az, index) => (
                        <RText key={index}>{az}</RText>
                        ))}
                    </RColumn>
                   
                </RColumn>                      
                </RGrid>
            <Space/>
            <RText size="16px">{descrizione}</RText>
            <Space height="50px"/>
          
            </div>
            </div>
            </div>
            {callRimandateAttesa.length > 0 &&<> <Space/>
            <SingleSezione>
            <RColumn>
                <Space/>
                <RColumn width={"100%"} horizontalAlign="start">
                <Title2>Chiamate con problemi</Title2>
                </RColumn>
                <Space/>
                <RColumn width={isMobile ? "100%" : "80%"} horizontalAlign="start">
                  
                    <Space/>
                    <RColumn width={"100%"}>                    
                        {callRimandateAttesa.map((call, index) => (
                            <SingleCallProb key={index} data={call} onElimina={(id) => setCancellaCall({open: true, id: id})} onRiprogramma={(id) => setRiprogrammaCall({open: true, id: id, callDoc: call})}/>
                        ))}
                    </RColumn>
                </RColumn>
            </RColumn>
            <Space/>
            </SingleSezione></>}
            <Space/>
            <SingleSezione>
            <RColumn>
                <Space/>
                <RColumn width={"100%"} horizontalAlign="start">
                <Title2>Chiamate prenotate</Title2>
                </RColumn>
                <Space/>
                <RColumn width={isMobile ? "100%" : "80%"} horizontalAlign="start">
                    <RRow>
                    <Title1 color={ColorPalette.accentGreen}>{futureCall.length}</Title1>
                    <SpaceHorizontal/>
                    <RText>{futureCall.length == 1 ? "chiamata" : "chiamate"} da fare</RText>
                    </RRow>
                    <Space/>
                    <RColumn width={"100%"}>                    
                        {futureCall.map((call, index) => (
                            <SIngleChiamata key={index} data={call} onElimina={(id) => setCancellaCall({open: true, id: id})} onRiprogramma={(id) => setRiprogrammaCall({open: true, id: id, callDoc: call})}/>
                        ))}
                    </RColumn>
                </RColumn>
            </RColumn>
            <Space/>
            </SingleSezione>
            <Space/>
            <SingleSezione>
            <Space/>
           
            <RRow>
                <Title2>Le tue competenze</Title2>
                <SpaceHorizontal/>
                <ModifyBtn onClick={() => setIsModCompetenze(true)}/>
            </RRow>

            {competenze.length === 0 && <div style={{width: "200px"}}>
                <Space/>
                 <SecondaryBtn onClick={() => setIsModCompetenze(true)}>Aggiungi competenze</SecondaryBtn>
            </div>
            }
            <Space/>
            <RGrid>
                {competenze.map((comp, ind) => (
                    <SingleCompetenza key={ind} name={comp.label}/>
                ))}
            </RGrid>
            <Space/>
            </SingleSezione>
            <Space height="100px"/>
        </>
    );
}

export default DashboardPage;