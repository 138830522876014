import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { DropDownMenu, InputField, InputFieldMultiline, RColumn, RRow, RText, Space, Title2 } from "../compnents/otherCOmps";
import { useTheme } from "../util/theme";
import { BaseData, ColorPalette } from "../util/const";
import { SelezionaGiorniOrai } from "../compnents/selectOrari";

const ModifyProfilePage = () =>{

    
    const [isLoading, setIsLoading] = useState(true);

    const [userData, setUserData] = useState({});
    const { isDarkMode, isMobile } = useTheme();

    const titoliCategorie = ["Informazioni personali", "Esperienza lavorativa", "Su di te","Quando sei disponibile per le call?"];

    useEffect(() => {
        getData();
    },[]);

    async  function getData(){
        try{
        const ref = doc(db, "mentors", auth.currentUser.uid);
        const userDoc = await getDoc(ref);
        if(!userDoc.exists() || userDoc.data()["approvato"] !== true){
            //prima volta
            return;
        }
        setUserData(userDoc.data());
    }catch(e){

    }
    setIsLoading(false);
    }
    function changeFieldUserData(key, val){
        setUserData((prev) => ({
            ...prev, 
            [key]: val,
        }));
    }

    if(isLoading){
        return <h1>Loading</h1>
    }


    return(
        <>
        <Space height="100px"/>
       
        <RColumn padding={isMobile ? "0px 12px": ""} horizontalAlign="center" width={isMobile ? "" : "100%"}>
        <div style={{ borderRadius: "14px",width:"100%", maxWidth: "840px", backgroundColor: isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight}}>
                <div style={{padding: "18px"}}>
                <Space/>
                <Title2>{titoliCategorie[0]}</Title2>
                <Space/>
                <RText fontWeight="bold" size="18px">Nome</RText>
                <InputField placeHolder={"Nome"} value={userData.nome} setValue={(t) => changeFieldUserData("nome", t)}/>
                <Space/>
                <RText fontWeight="bold" size="18px">Città</RText>
                <InputField placeHolder={"Città"} value={userData.city} setValue={(t) => changeFieldUserData("city", t)}/>
                <Space/>
                <RText fontWeight="bold" size="18px">Anno di nascita</RText>
                <InputField placeHolder={"Anno di nascita"} value={userData.annoNascita} setValue={(t) => changeFieldUserData("annoNascita", t)}/>
                <Space/>
                <Space/>

                <Title2>{titoliCategorie[1]}</Title2>
                <Space/>
                <RText fontWeight="bold" size="18px">Categoria</RText>
                <DropDownMenu width={isMobile ? window.innerWidth - 80 : "800px"} options={BaseData.categorieOptions} placeHolder={"Categoria"} selectedValue={BaseData.categorieOptions.find((opzione) => opzione.value === userData.categoria)} onChange={(v) => changeFieldUserData("categoria", v.value)}/>
                <Space/>
                <RText fontWeight="bold" size="18px">Sotto categoria</RText>
                <DropDownMenu width={isMobile ? window.innerWidth - 80 : "800px"} siDisabled={!userData.categoria} isMulti={true} options={BaseData.sottoCategorieOptions[userData.categoria]} placeHolder={"Sotto categoria"} selectedValue={(BaseData.sottoCategorieOptions[userData["categoria"]] ?? []).filter(c =>  (userData["sotto_categoria"] ?? []).includes(c.value))} onChange={(v) => changeFieldUserData("sotto_categoria", v.map(c => c.value))}/>
                <Space/>
                <RText fontWeight="bold" size="18px">Anni esperienza</RText>
                <InputField numbers={true} placeHolder={"Anni esperienza"} value={userData.anni_esperienza} setValue={(t) => changeFieldUserData("anni_esperienza", t)}/>
                <Space/>
                <RText fontWeight="bold" size="18px">Azienda in cui lavori o hai lavorato</RText>
                <InputField placeHolder={"Azienda in cui lavori o hai lavorato"} value={userData.azienda ?? ""} setValue={(t) => changeFieldUserData("azienda", t)}/>
                <Space/>
                <Space/>

                <Title2>{titoliCategorie[2]}</Title2>
                <Space/>
                <RText fontWeight="bold" size="18px">Descrivi chi sei</RText>
                <InputFieldMultiline placeHolder={"Descrivi chi sei"} value={userData.desc ?? ""} setValue={(t)=> changeFieldUserData("desc", t)}/> 
                <Space/>
                <RText fontWeight="bold" size="18px">Generi</RText>
                <DropDownMenu width={isMobile ? window.innerWidth - 80 : "800px"} isMulti={true} selectedValue={(BaseData.generiOptions ?? []).filter(c =>  (userData["generi"] ?? []).includes(c.value))} onChange={(v) => changeFieldUserData("generi", v.map(c => c.value))} placeHolder={"Seleziona i generi che ti descrivono"} options={BaseData.generiOptions}/>
                <Space/>
                <RText fontWeight="bold" size="18px">Link ad un social che meglio ti rappresenta</RText>
                <InputField placeHolder={"Link ad un social che meglio ti rappresenta"} value={userData.linkSocial ?? ""} setValue={(t) => changeFieldUserData("linkSocial", t)}/>
                <Space/>
                <Space/>
                <Title2>{titoliCategorie[3]}</Title2>
                <Space/>
               
                <RText fontWeight="bold" size="18px">Giorni e orari</RText>
                <Space/>
                <SelezionaGiorniOrai selectDispCallback={(v) => changeFieldUserData("disp", v)} initialSelectedDisp={userData.disp}/>
               
            </div>
        </div>
        </RColumn>
        <Space height="200px"/>
        </>
    )
}

export default ModifyProfilePage;