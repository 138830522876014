import "./App.css";
import { ColorPalette } from "./util/const";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  MemoryRouter,
  HashRouter,
} from "react-router-dom";
import StartingPage from "./startingPage";
import Header from "./pages/header";
import { Title1 } from "./compnents/otherCOmps";
import { DarkModeProvider, useTheme } from "./util/theme";
import "react-toastify/dist/ReactToastify.css";
import { logOut } from "./firebase";

function App() {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <div
      className="App"
      style={{
        backgroundColor: isDarkMode
          ? ColorPalette.bgColorDark
          : ColorPalette.bgColorLight,
        minHeight: "100vh",
      }}
    >
      <HashRouter>
        <StartingPage />
      </HashRouter>
    </div>
  );
}

export default App;
