import "./comps.css";
import "../App.css";
import { ColorPalette } from "../util/const";
import { useTheme } from "../util/theme";
import Select from "react-select";
import React, { useRef, useState } from "react";
import eyeOpenIcon from "../assets/view.png";
import eyeCloseIcon from "../assets/hide.png";
import pen1Img from "../assets/pen.png";
import pen2Img from "../assets/pen-2.png";
import { capitalizeFirstLetter } from "../util/util";

export function Space({ height = "20px" }) {
  return <div style={{ height: height }}></div>;
}
export function SpaceHorizontal({ width = "10px" }) {
  return <div style={{ width: width }}></div>;
}
export const SecondaryBtn = ({ children, onClick }) => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  return (
    <div
      className="secondary-button"
      style={{ color: isDarkMode ? "white" : "black" }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export const BlackButton = ({ children, onClick }) => {
  return (
    <div className="black-button" style={{ color: "white" }} onClick={onClick}>
      {children}
    </div>
  );
};

export function PrimaryBtn({ children, _width = null, onClick }) {
  return (
    <button
      className="primary-button"
      style={{ width: _width }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function RColumn({
  minWidth = null,
  children,
  horizontalAlign = "center",
  verticalAlignment = "start",
  height = null,
  width = null,
  bgColor,
  padding = null,
  flex = null,
}) {
  const style = {
    height: height,
    display: "flex",
    alignItems: horizontalAlign,
    justifyContent: verticalAlignment,
    flexDirection: "column",
    width: width,
    backgroundColor: bgColor,
    padding: padding,
    minWidth: minWidth,
    flex: flex,
  };
  return <div style={style}>{children}</div>;
}

export function RPadding({ children, padding }) {
  return <div style={{ padding: padding }}>{children}</div>;
}
export function RRow({
  children,
  horizontalAlign = "start",
  verticalAlignment = "center",
  width = null,
  height = null,
  minHeight = null,
  padding = null,
  bgColor = null,
}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexDirection: "row",
    backgroundColor: bgColor,
    padding: padding,
    minHeight: minHeight,
  };
  return <div style={style}>{children}</div>;
}
export function RGrid({
  children,
  horizontalAlign = "start",
  verticalAlignment = "center",
  width = null,
  height = null,
  minHeight = null,
  padding = null,
  bgColor = null,
}) {
  const style = {
    width: width,
    height: height,
    display: "flex",
    alignItems: verticalAlignment,
    justifyContent: horizontalAlign,
    flexWrap: "wrap",
    backgroundColor: bgColor,
    padding: padding,
    minHeight: minHeight,
  };
  return <div style={style}>{children}</div>;
}

export function RText({
  children,
  align = null,
  size = "18px",
  color = null,
  fontWeight = "normal",
}) {
  const { isDarkMode, toggleDarkMode } = useTheme();
  const dColor = isDarkMode
    ? ColorPalette.textSecondaryDark
    : ColorPalette.textSecondaryLight;
  return (
    <div
      style={{
        color: color ?? dColor,
        fontSize: size,
        fontWeight: "normal",
        fontFamily: "Riegla",
        textAlign: align,
        fontWeight: fontWeight,
      }}
    >
      {children}
    </div>
  );
}

export function Title1({ children, align = null, color = null }) {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <div
      style={{
        color: color ?? (isDarkMode ? "white" : "black"),
        fontSize: "32px",
        fontWeight: 900,
        fontFamily: "factorB",
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}
export function Title2({ children, align = null, size = "24px" }) {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <div
      style={{
        color: isDarkMode ? "white" : "black",
        fontSize: size,
        fontWeight: "bold",
        fontFamily: "factorB",
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}
export function SubTitle1({ children, align = null }) {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <div
      style={{
        color: isDarkMode
          ? ColorPalette.textSecondaryDark
          : ColorPalette.textSecondaryLight,
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: align,
      }}
    >
      {children}
    </div>
  );
}

export function InputField({
  placeHolder,
  setValue,
  value,
  maxChar = null,
  type = "text",
  autoComplete = null,
  numbers = false,
  isPassword = false,
  firstUpperCase = false,
}) {
  const { isDarkMode, toggleDarkMode } = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleNumeroChange = (event) => {
    if (numbers == true) {
      const inputValue = event.target.value;
      if (!inputValue) {
        setValue("");
        return;
      }
      if (/^\d*\.?\d*$/.test(inputValue))
        setValue(parseInt(event.target.value));
    } else {
      const t = firstUpperCase
        ? capitalizeFirstLetter(event.target.value)
        : event.target.value;
      setValue(t);
    }
  };
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <input
        type={isPassword && !showPassword ? "password" : type}
        autoComplete={autoComplete}
        maxLength={maxChar}
        value={value}
        onChange={handleNumeroChange}
        placeholder={placeHolder}
        style={{
          fontWeight: "normal",
          fontFamily: "Riegla",
          margin: "6px 0px",
          color: isDarkMode
            ? ColorPalette.textSecondaryDark
            : ColorPalette.textSecondaryLight,
          fontSize: "16px",
          padding: "16px 20px",
          boxSizing: "border-box",
          borderRadius: "12px",
          width: "100%",
          border: "none",
          outline: "none",
          backgroundColor: isDarkMode
            ? ColorPalette.containerSecondaryColorDark
            : ColorPalette.containerSecondaryColorLight,
        }}
      />
      {isPassword && (
        <img
          style={{
            position: "absolute",
            top: "50%",
            right: "15px",
            transform: "translateY(-50%)",
          }}
          src={showPassword ? eyeCloseIcon : eyeOpenIcon}
          width={"20px"}
          onClick={toggleShowPassword}
        />
      )}
    </div>
  );
}
export function InputFieldMultiline({
  placeHolder,
  setValue,
  value,
  maxChar = null,
  numRighe = 5,
}) {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <textarea
      maxLength={maxChar}
      rows={numRighe}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeHolder}
      style={{
        fontWeight: "normal",
        fontFamily: "Riegla",
        resize: "none",
        margin: "6px 0px",
        color: isDarkMode
          ? ColorPalette.textSecondaryDark
          : ColorPalette.textSecondaryLight,
        fontSize: "16px",
        padding: "16px 20px",
        boxSizing: "border-box",
        borderRadius: "12px",
        width: "100%",
        border: "none",
        outline: "none",
        backgroundColor: isDarkMode
          ? ColorPalette.containerSecondaryColorDark
          : ColorPalette.containerSecondaryColorLight,
      }}
    />
  );
}
export const customStylesDropMenu = (width, isDarkMode) => {
  return {
    control: (base, state) => ({
      ...base,
      width: width,
      margin: "8px 0px",
      background: isDarkMode
        ? ColorPalette.containerSecondaryColorDark
        : ColorPalette.containerSecondaryColorLight,
      cursor: "pointer",
      borderRadius: "10px",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      fontWeight: "normal",
      fontFamily: "Riegla",
      padding: "6px 14px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      background: state.isSelected
        ? ColorPalette.accentOrange
        : ColorPalette.bgColorLight,
      cursor: "pointer",
      backgroundColor: "red",
      fontWeight: "normal",
      fontFamily: "Riegla",
      "&:hover": {
        backgroundColor: "#e0835c",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontWeight: "normal",
      fontFamily: "Riegla",
      color: isDarkMode
        ? ColorPalette.textSecondaryDark
        : ColorPalette.textSecondaryLight,
    }),
  };
};
export function DropDownMenu({
  options,
  selectedValue,
  onChange,
  placeHolder,
  isMulti,
  siDisabled,
  width = "500px",
}) {
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <Select
      isDisabled={siDisabled}
      isMulti={isMulti}
      options={options}
      value={selectedValue}
      onChange={onChange}
      placeholder={placeHolder}
      styles={customStylesDropMenu(width, isDarkMode)}
    />
  );
}
export function FileUploadButton({
  onFileChange,
  accept = ".png, .jpg, .jpeg",
}) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      onFileChange(selectedFile);
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept={accept}
      />
      <SecondaryBtn onClick={handleButtonClick}>Seleziona un file</SecondaryBtn>
    </div>
  );
}
export function FileUploadButtonWhite({
  onFileChange,
  text = "Seleziona un file",
}) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      onFileChange(selectedFile);
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
      />
      <BlackButton onClick={handleButtonClick}>{text}</BlackButton>
    </div>
  );
}
export function FileModButton({ onFileChange }) {
  const fileInputRef = useRef(null);
  const { isDarkMode } = useTheme();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      onFileChange(selectedFile);
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
      />
      <div
        onClick={handleButtonClick}
        style={{
          position: "absolute",
          top: "-8px",
          right: "-8px",
          width: "30px",
          height: "30px",
          backgroundColor: isDarkMode
            ? ColorPalette.containerSecondaryColorDark
            : ColorPalette.containerSecondaryColorLight,
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModifyBtn />
      </div>
    </div>
  );
}

export function ModifyBtn({ onClick, padding }) {
  const { isDarkMode } = useTheme();
  return (
    <div style={{ cursor: "pointer", padding: padding }}>
      <img
        src={isDarkMode ? pen1Img : pen2Img}
        width={"16px"}
        onClick={onClick}
      />
    </div>
  );
}
