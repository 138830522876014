import { RRow, RText, SecondaryBtn} from '../compnents/otherCOmps';
import logoBianco from "../assets/LOGO_bianco.png";
import logoNero from "../assets/LOGO_nero.png";
import { ColorPalette, setChangeDarkMode } from "../util/const";
import { useNavigate } from 'react-router-dom';
import { auth, logOut } from '../firebase';
import { useEffect } from 'react';
import { useTheme } from '../util/theme';
import sun from "../assets/sun.png"
import moonBlack from "../assets/moon-black.png";

const Header = ({isLogged}) => {

  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode, isMobile } = useTheme();


  const accedi = () => {
    navigate("/accedi");
  }

 return(
  <div style={{position:"fixed",width:"100%", zIndex: 5}}>
    <RRow horizontalAlign='space-between' padding={isMobile ? "20px 22px" : "20px 100px"} height={"40px"} bgColor={isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight}>
        <img src={isDarkMode ? logoBianco : logoNero} width="100px" onClick={() => navigate("/")} style={{cursor:"pointer"}}/>
        <RRow>
          <img src={isDarkMode ? sun : moonBlack} width="20px" onClick={toggleDarkMode} style={{cursor:"pointer", marginRight: "12px"}}/>
          {!isLogged && <SecondaryBtn onClick={accedi}>Accedi</SecondaryBtn>}
          {isLogged && <SecondaryBtn onClick={logOut}>Esci</SecondaryBtn>}
        </RRow>
       
      </RRow>
    </div>
 ); 
}

export default Header;