import { useState } from "react";
import { InputField, PrimaryBtn, RColumn, RText, Space, Title1, Title2 } from "../compnents/otherCOmps"
import { ColorPalette } from "../util/const";
import { auth, login, sendLostPasswLink, verifyAccount } from "../firebase";
import { useTheme } from "../util/theme";
import { showErrorNotification, showSuccessNotification } from "../compnents/errorMessagesComp";

const LoginPage = () => {
    const [emailField, setEmailField] = useState('');
    const { isDarkMode, isMobile } = useTheme();

    const [isPasswordLost, setIspasswLost] = useState(false);
    const [emailFieldLost, setEmailFieldLost] = useState('');
  const emailInputChange = (event) => {
    setEmailField(event);
  };
 
  const [passwordield, setPasswordField] = useState('');

  const passwordInputChange = (event) => {
    setPasswordField(event);
  };

  const accedi = async () => {
    try{
        await login(emailField, passwordield);
    }catch(e){
      showErrorNotification("Email o password sbagliata");
        console.log(e);
    }
}
async function lostPassw(){
    await sendLostPasswLink(emailFieldLost);
    showSuccessNotification("Link inviato");
    setIspasswLost(false);
}
return(
        <>
        <RColumn width={"100%"} height={"90vh"} horizontalAlign="center" verticalAlignment="center">
        <div style={{ borderRadius: "14px",width: isMobile ? "auto" : "400px",padding:"16px 20px", backgroundColor: isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight}}>
       {!isPasswordLost && <RColumn>
            <Title2 align={"center"}>Accedi</Title2>
            <Space/>
            <RText>Accedi con le tue credenziali HAT Mentor</RText>
            <Space/>
             <InputField placeHolder={"Email"} value={emailField} setValue={emailInputChange} autoComplete={"email"}/>
            <InputField isPassword={true} placeHolder={"Password"} value={passwordield} setValue={passwordInputChange}/>
            <Space/>
            <PrimaryBtn onClick={accedi}>Accedi</PrimaryBtn>
            <Space/>
            <div style={{cursor: "pointer"}} onClick={() => setIspasswLost(true)}>
              <RText size="16px">Password dimenticata?</RText>
            </div>
           
        </RColumn>}
        { isPasswordLost &&
           <RColumn>
           <Title2 align={"center"}>Reset password</Title2>
           <Space/>
           <InputField placeHolder={"Email"} value={emailFieldLost} setValue={(e) => setEmailFieldLost(e)} autoComplete={"email"}/>
           <Space/>
           <PrimaryBtn onClick={lostPassw}>Reset password</PrimaryBtn>
           <Space/>
           <RText size="16px">Riceverai una email con un link per reimpostare la tua password</RText>
           </RColumn>}
        </div>
        </RColumn>
        </>
    )
}
export default LoginPage;