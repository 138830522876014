import { initializeApp } from "firebase/app";
import {getAuth, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, getFirestore, getDoc, setDoc} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions"
import {getStorage} from "firebase/storage"
import {getAnalytics} from "firebase/analytics"

const config = {
    apiKey: "AIzaSyCxPu6TSrToS4QZt_QtikjPiNDdylzpJSk",
    authDomain: "hat-music-hub.firebaseapp.com",
    projectId: "hat-music-hub",
    storageBucket: "hat-music-hub.appspot.com",
    messagingSenderId: "200907133189",
    appId: "1:200907133189:web:fed1d6cb291e39787de76c",
    measurementId: "G-ZHS1NPKB2C"
};

const app = initializeApp(config);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export const sendLostPasswLink = async (email) => {
     await sendPasswordResetEmail(auth, email);
}

export const createAccount = async (email, password) => {
  /*   try{
        const createUserFunc = httpsCallable(functions, 'createUserWithVerification');
        const result = await createUserFunc({ email: email, password: password });
        console.log(result.data);
        if(!result.data.success){
            throw "Errore";
        }
    }catch(e){
        console.log(e);
        throw e;
    } */
    try{
        const userCred = await createUserWithEmailAndPassword(auth,email, password);
        await setDoc(doc(db, "mentors", userCred.user.uid), {"approvato": false, "creation_date": Date.now()});
    }catch(e){
        throw e;
    }
}

export const verifyAccount = async (email, code) => {
    try{
        const verifyEmail = httpsCallable(functions, 'verifyEmailCode');
        const result = await verifyEmail({ code: code.toString()});
        console.log(result.data);
        if(!result.data.success){
            throw "Errore";
        }
    }catch(e){
        console.log(e);
        throw e;
    }
}

export const login = async (email, password) => {
    try{
        await signInWithEmailAndPassword(auth, email, password);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export const logOut = async () => {
    await signOut(auth);
}