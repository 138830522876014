import { ColorPalette, prefLinkSocial, socialIDToImg, socialLogos } from "../util/const"
import { useTheme } from "../util/theme"
import { PrimaryBtn, RColumn, RGrid, RRow, RText, Space, SpaceHorizontal, Title2 } from "./otherCOmps"
import { format } from 'date-fns';
import { it } from 'date-fns/locale';
export const SIngleChiamata = ({data, onElimina, onRiprogramma}) => {
    const {isMobile} = useTheme();
    console.log(data);
    return (
        <div style={{border: "2.5px solid " + ColorPalette.primaryColor, borderRadius: "12px", width: "100%", marginBottom: "8px"}}>
            <div style={{padding: "16px"}}>
            <RRow horizontalAlign="space-between">
                <RRow>
                    <RText size="18px" fontWeight="bold">{format(data.data, "EEEE d MMMM", {locale: it})}</RText>
                    <SpaceHorizontal/>
                    <RText>{data.ora}</RText>
                </RRow>
                <RRow>
                    <div style={{color: "red", padding: "8px",fontSize: "14px", cursor: "pointer"}} onClick={() => onRiprogramma(data.id)}>
                        Riprogramma call
                    </div>
                    <SpaceHorizontal/>
                    <div style={{color: "red", padding: "8px",fontSize: "14px", cursor: "pointer"}} onClick={() => onElimina(data.id)}>
                        Cancella call
                    </div>
                </RRow>
               
            </RRow>
            <Space/>
            <RGrid horizontalAlign="space-between" verticalAlignment="start">
                <RColumn horizontalAlign="start">
                    <RRow verticalAlignment="start">
                        <img  style={{objectFit: 'cover',borderRadius: "100%"}} width={"70px"} height={"70px"} src={data.user?.profilePicLink ?? ""}/>
                        <SpaceHorizontal/>
                        <RColumn horizontalAlign="start">
                            <RRow>
                                <Title2 size="18px">{data.user?.name ?? "--"}</Title2>
                                <SpaceHorizontal/>
                                <RText>{data.user?.tipoArtista ?? ""}</RText>
                            </RRow>
                            <RRow>
                                {(data.user?.generi ?? []).map((gen, ind) =>(
                                    <div key={ind} style={{marginRight: "6px"}}>
                                    <RText >{gen}</RText>
                                    </div>
                                ))}
                            </RRow>
                            <Space height="6px"/>
                           <RText>{data.user?.bio}</RText>
                        </RColumn>
                    </RRow>
                    <Space/>
                    <RRow>
                    {Object.values(data.user?.socials ?? {}).map((soc, ind) => (
                        <a key={ind} href={prefLinkSocial[soc.id] + soc.username} target="_blank">
                            <img style={{ padding: "0px 8px" }} key={ind} src={socialIDToImg[soc.id]} width="24px"/>
                        </a>
                    ))}
                    </RRow>
                    {isMobile && <Space/>}
                </RColumn>
                <RColumn width={isMobile ? "100%" : "50%"}>
                    <RText size="16px">{data.desc}</RText>
                    <Space/>
                    <a href={data.meetLink} target="_blank">
                        <PrimaryBtn>Vai a meet</PrimaryBtn>
                    </a>
                </RColumn>
            </RGrid>
            </div>
        </div>
    )
}