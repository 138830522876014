import { useState } from "react";
import { InputField, PrimaryBtn, RColumn, RText, Space, Title1, Title2 } from "../compnents/otherCOmps"
import { ColorPalette } from "../util/const";
import { auth, verifyAccount } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../util/theme";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { LoadingModal } from "../compnents/loadingComp";

const VerifyEmailPage = () => {
    const { isDarkMode, isMobile } = useTheme();

    const [codeField, setCodeField] = useState('');
    const [isLoadingModal, setIsLoadingModal] = useState(false);

    const navigate = useNavigate();

    const codeInputChange = (event) => {
        setCodeField(event);
    };


    const verificaCode = async () => {
        setIsLoadingModal(true);
        try{
            await verifyAccount(auth.currentUser.email, codeField);
            await auth.currentUser.reload();
            navigate("/onboarding");
        }catch(e){
            console.log("errore verifica mail", e);
            showErrorNotification("Codice sbagliato");
        }
        setIsLoadingModal(false);
    }
    return(
        <>
        {isLoadingModal && <LoadingModal text="Verifico codice..."/>}
        <RColumn width={ isMobile ? "auto": "100%"} height={"90vh"} horizontalAlign="center" verticalAlignment="center" padding={isMobile ? "0px 18px" : ""}>
        <div style={{ borderRadius: "14px",width: isMobile ? "auto": "400px",padding:"16px 20px", backgroundColor: isDarkMode ? ColorPalette.containerColorDark : ColorPalette.containerColorLight}}>
        <RColumn>
            <Title2 align={"center"}>Verifica la tua email</Title2>
            <Space/>
            <RText>Controlla la tua email e inserisci il codice che hai ricevuto</RText>
            <Space/>
            <InputField numbers={true} value={codeField} setValue={codeInputChange} maxChar={6} placeHolder={"Codice"}/>
            <Space/>
            <PrimaryBtn onClick={verificaCode}>Verifica</PrimaryBtn>
        </RColumn>
     
        </div>
        </RColumn>
        </>
    )
}
export default VerifyEmailPage;