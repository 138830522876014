import { useEffect, useState } from "react"
import { RColumn, RGrid, RRow, RText, Space, SpaceHorizontal, Title1, Title2, customStylesDropMenu } from "./otherCOmps"
import { ColorPalette } from "../util/const";
import Select from 'react-select';
import { useTheme } from "../util/theme";

export const SelezionaGiorniOrai = ({width = "100%", selectDispCallback, initialSelectedDisp = {}}) => {
    const {isDarkMode, isMobile} = useTheme();

    const giorni = [
        { index: 1, label: "Lunedì", abbr: "Lun" },
        { index: 2, label: "Martedì", abbr: "Mar" },
        { index: 3, label: "Mercoledì", abbr: "Mer" },
        { index: 4, label: "Giovedì", abbr: "Gio" },
        { index: 5, label: "Venerdì", abbr: "Ven" },
        { index: 6, label: "Sabato", abbr: "Sab" },
        { index: 7, label: "Domenica", abbr: "Dom" }
    ]

    const orariSelezionabili = [];
    const orarioDef = [{start: "08:00", end: "22:00"}];

    for (let ora = 8; ora <= 22; ora++) {
    for (let minuto = 0; minuto < 60; minuto += 30) {
        const oraFormat = ora.toString().padStart(2, '0');
        const minutoFormat = minuto.toString().padStart(2, '0');
        const valore = `${oraFormat}:${minutoFormat}`;
        orariSelezionabili.push({ value: valore, label: valore });
    }
    }

    const [selectedDisp, setSelectedDisp] = useState(initialSelectedDisp);

    useEffect(() => {
       /*  const giorniDef = [1,3];
        
        let dispDef = {};
        giorniDef.map((val, index) => {
            dispDef["g"+giorni[val].index] = {giorno: giorni[val].label, id: giorni[val].index, orari: orarioDef}
        })
        setSelectedDisp(dispDef);
        console.log(dispDef); */
    }, []);

    useEffect(() => {
        selectDispCallback(selectedDisp);
    }, [selectedDisp]);

    const onSelectGiorno = (index) => {
        const key = "g" + giorni[index].index;

        if(key in selectedDisp){
            setSelectedDisp(prevState => {
                const { [key]: _, ...nuovoStato } = prevState;
                return nuovoStato;
              });
              return;
        }
        const newG = {
            giorno: giorni[index].label,
            id: giorni[index].index,
            orari: orarioDef,
        }
       
        setSelectedDisp(prev => ({...prev, [key]: newG}));
    }

    const onAddFascia = (index) => {
        const key = "g" + giorni[index].index;
        const newOrario = {
            
        }
        setSelectedDisp((prevOggetto) => ({
            ...prevOggetto,
            [key]: { ...prevOggetto[key], orari: [...prevOggetto[key].orari, newOrario] },
          }));

    }
    const onRemoveFascia = (index) => {
        const key = "g" + giorni[index].index;
      
        setSelectedDisp((prevOggetto) => ({
            ...prevOggetto,
            [key]: { ...prevOggetto[key], orari: [prevOggetto[key].orari[0]] },
          }));

      };

    const onSelectOrario = (type, v, indexGiorn, indexFascia) => {
        const ora = v.value;
        const key = "g" + giorni[indexGiorn].index;

        let newOrari = selectedDisp[key].orari;
        newOrari[indexFascia][type] = ora;

        const newG = {
            giorno: giorni[indexGiorn].label,
            id: giorni[indexGiorn].index,
            orari: newOrari,
        }
       
        setSelectedDisp(prev => ({...prev, [key]: newG}));
    }
    const SingleOrario = ({ora, type, index,indexFascia}) => {
        const key = "g" + giorni[index].index;
        let opt = orariSelezionabili;
        const otherStart = selectedDisp[key].orari[indexFascia === 0 ? 1 : 0]?.start;
        const otherEnd = selectedDisp[key].orari[indexFascia === 0 ? 1 : 0]?.end;
        if(type == "end"){
            const startTime = selectedDisp[key].orari[indexFascia].start ?? "00:00";
            if(otherStart){
                opt = orariSelezionabili.filter((v) => (v.value < otherStart || v.value > otherEnd) && v.value > startTime);
            }else{
                opt = orariSelezionabili.filter((v) => v.value > startTime);
            }
           
        }else{
            const endTime = selectedDisp[key].orari[indexFascia].end ?? "23:00";
            if(otherStart){
                opt = orariSelezionabili.filter((v) => (v.value < otherStart || v.value > otherEnd) && v.value < endTime)
            }else{
                opt = orariSelezionabili.filter((v) => v.value < endTime)
            }
           
        }
     

        return(
            <Select placeholder={"Ora"} value={{value: ora, label: ora}} options={opt} onChange={(v) => onSelectOrario(type, v, index,indexFascia)} styles={customStylesDropMenu("", isDarkMode)}/>
        )
    }
    const SingleFasciaOraria = ({orari, ind,indexFascia}) => {
        return (
            <>
            <RRow>
            <SingleOrario ora={orari.start} type={"start"} index={ind} indexFascia={indexFascia}/>
            <SpaceHorizontal/>
            <RText>-</RText>
            <SpaceHorizontal/>
            <SingleOrario ora={orari.end} type={"end"} index={ind} indexFascia={indexFascia}/>
            </RRow>
            </>
        )
    }
    const SingleGiorn = ({giorno, ind}) => {
      
        return (
            <>
            <RGrid horizontalAlign="start" width={width} minHeight={"42px"} verticalAlignment="center" padding={isMobile ? "2px 0px" : ""}>
            <input
                type="checkbox"
                checked={("g"+ giorno.index) in selectedDisp}
                onChange={() => onSelectGiorno(ind)}
            />
             <SpaceHorizontal width="12px"/>
            <div style={{width: isMobile ? "60px" : "110px"}}>
                <RText fontWeight="bold">{isMobile ? giorno.abbr : giorno.label}</RText>
            </div>
            <SpaceHorizontal/>
            <RColumn>
                {("g"+ giorno.index) in selectedDisp && selectedDisp["g"+giorno.index].orari.map((val, index) => (
                    <RRow key={index}>
                        <SingleFasciaOraria orari={val} ind={ind} indexFascia={index}/>
                        <SpaceHorizontal width="20px"/>
                        {("g"+ giorno.index) in selectedDisp && selectedDisp["g"+giorno.index].orari.length < 2 &&
                            <div style={{cursor: "pointer"}} onClick={() => onAddFascia(ind)}>
                                <Title2>+</Title2>
                            </div>
                        }
                        {("g"+ giorno.index) in selectedDisp && selectedDisp["g"+giorno.index].orari.length == 2 && index == 1 &&
                            <div style={{cursor: "pointer"}} onClick={() => onRemoveFascia(ind)}>
                                <Title2>-</Title2>
                            </div>
                        }
                    </RRow>
                ))}
            </RColumn>
            {("g"+ giorno.index) in selectedDisp &&<>
            
          
            
            </>}
           
            </RGrid>
            </>
        );
    }
  
    return(
        <>
        <RColumn width={"100%"}>
       
        {giorni.map((val, index) => (
            <SingleGiorn key={index} giorno={val} ind={index}/>
        ))}
        <RText><span style={{fontWeight: "bold", color: CalcolaDurataTotaleOreDisp(selectedDisp) < 2 ? "red" : ""}}>{CalcolaDurataTotaleOreDisp(selectedDisp)}</span> ore selezionate</RText>
        </RColumn>
        <Space/>
        </>
    )
}

export  const CalcolaDurataTotaleOreDisp = (giorniDips) => {
    let durataTotale = 0;
  
    // Cicla attraverso ogni giorno
    for (const giorno in giorniDips) {
      if (giorniDips.hasOwnProperty(giorno)) {
        const orari = giorniDips[giorno].orari;
  
        // Cicla attraverso ogni orario del giorno
        orari.forEach((orario) => {
            if(orario.start && orario.end){
                const startTime = orario.start.split(':');
                const endTime = orario.end.split(':');
        
                const startMinutes = parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
                const endMinutes = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
                const differenzaInOre = (endMinutes - startMinutes) / 60;
        
                durataTotale += differenzaInOre;
            }
        });
      }
    }
  
    return durataTotale;
  };