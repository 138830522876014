// DarkModeContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const DarkModeContext = createContext();

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

 const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode, isMobile }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(DarkModeContext);
};
