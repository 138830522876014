import {
  InputField,
  PrimaryBtn,
  RColumn,
  RGrid,
  RPadding,
  RRow,
  RText,
  SecondaryBtn,
  Space,
  SubTitle1,
  Title1,
  Title2,
} from "../compnents/otherCOmps";
import { ColorPalette } from "../util/const";
import fioreImg from "../assets/fiore.png";
import { useEffect, useState } from "react";
import { auth, createAccount, login } from "../firebase";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import { useTheme } from "../util/theme";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { LoadingModal } from "../compnents/loadingComp";

const HomePage = () => {
  const [emailField, setEmailField] = useState("");
  const { isDarkMode, toggleDarkMode, isMobile } = useTheme();

  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const navigate = useNavigate();

  const emailInputChange = (event) => {
    setEmailField(event);
  };

  const [passwordield, setPasswordField] = useState("");
  const [repPasswordield, setRepPasswordField] = useState("");

  const passwordInputChange = (event) => {
    setPasswordField(event);
  };

  const registrati = async () => {
    if (passwordield !== repPasswordield) {
      showErrorNotification("Le password non corrispondono");
      return;
    }
    setIsLoadingModal(true);
    try {
      await createAccount(emailField, passwordield);
    } catch (e) {
      showErrorNotification("L'account esiste già");
      console.log("Error create account: ", e);
    }
    setIsLoadingModal(false);
  };
  const accedi = () => {
    navigate("/accedi");
  };

  return (
    <>
      {isLoadingModal && <LoadingModal />}
      <Space height={isMobile ? "100px" : "200px"} />
      <div style={{ padding: isMobile ? "0px 22px" : "0px 100px" }}>
        <RGrid verticalAlignment="start" width={"auto"}>
          <RColumn
            horizontalAlign={isMobile ? "center" : "start"}
            width={isMobile ? "100%" : "50%"}
          >
            <Title1>Diventa un mentor di HAT Music</Title1>
            <Space />
            <SubTitle1>
              {" "}
              Ispira e scopri prima degli altri i futuri artisti emergenti
            </SubTitle1>
            <Space />
            <RText>
              Entra come mentor in HAT Digital Hub e parla con le future
              generazioni di artisti.
            </RText>
            <Space /> <Space />
            <div style={{ width: isMobile ? "100%" : "230px" }}>
              <a href="https://hatmusic.it" target="_blank">
                <SecondaryBtn>Scopri di più su HDH</SecondaryBtn>
              </a>
            </div>
            <Space />
            {!isMobile && <img src={fioreImg} width={"90%"} />}
          </RColumn>
          <RColumn width={isMobile ? "100%" : "50%"}>
            <div
              style={{
                borderRadius: "14px",
                width: "100%",
                padding: "0px 0px 0px 0px",
                backgroundColor: isDarkMode
                  ? ColorPalette.containerColorDark
                  : ColorPalette.containerColorLight,
              }}
            >
              <RColumn padding={isMobile ? "0px 16px" : "0px 50px"}>
                <Space />
                <Title2>Candidati ora</Title2>
                <Space />
                <InputField
                  placeHolder={"Email"}
                  value={emailField}
                  setValue={emailInputChange}
                  autoComplete={"email"}
                />
                <Space />
                <InputField
                  isPassword={true}
                  placeHolder={"Password"}
                  value={passwordield}
                  setValue={passwordInputChange}
                />
                <InputField
                  isPassword={true}
                  placeHolder={"Ripeti password"}
                  value={repPasswordield}
                  setValue={(e) => setRepPasswordField(e)}
                />
                <Space height={isMobile ? "14px" : "40px"} />
                <RText size="14px">
                  Registrandoti accetti i{" "}
                  <a
                    target="_blank"
                    className="link"
                    href="https://hatmusic.it/termini-condizioni/"
                  >
                    Termini e il Contratto sui servizi
                  </a>{" "}
                  di HAT Music Inc.
                </RText>
                <Space height={isMobile ? "20px" : "80px"} />
                <PrimaryBtn onClick={registrati}>Comincia</PrimaryBtn>
                <Space />
                <RText size="15px">
                  Hai già un account?{" "}
                  <span
                    onClick={accedi}
                    style={{
                      cursor: "pointer",
                      color: isDarkMode ? "white" : "black",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Log in
                  </span>
                </RText>
                <Space />
                <Space />
              </RColumn>
            </div>
          </RColumn>
        </RGrid>
      </div>
      <Space />
    </>
  );
};
export default HomePage;
