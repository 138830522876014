import {
  DropDownMenu,
  FileUploadButton,
  InputField,
  InputFieldMultiline,
  PrimaryBtn,
  RColumn,
  RGrid,
  RRow,
  RText,
  Space,
  SpaceHorizontal,
  Title1,
  Title2,
} from "../compnents/otherCOmps";
import {
  BaseData,
  ColorPalette,
  anniNelSottoreOption,
  citiesOptions,
  generePersonaOptions,
  socialLogos,
  yearOptions,
} from "../util/const";
import guitarImg from "../assets/guitar.png";
import graphic1Img from "../assets/graphic2.png";
import graphic2Img from "../assets/graphic3.png";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "../util/theme";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import {
  CalcolaDurataTotaleOreDisp,
  SelezionaGiorniOrai,
} from "../compnents/selectOrari";
import { showErrorNotification } from "../compnents/errorMessagesComp";

const OnboardingPage = () => {
  const [step, setStep] = useState(0);
  const totSteps = 5;

  const frasiSotto = [
    <span>
      <strong>Chi è il mentor?</strong>
      <br />
      Il mentor è una persona che sa <strong>fare tesoro</strong> delle{" "}
      <strong>proprie esperienze</strong> per poterle{" "}
      <strong>condividere e ispirare</strong> chi è ancora inesperto. Sa
      ascoltare le difficoltà di chi ha davanti e fornire{" "}
      <strong>consigli unici</strong> basati su ciò che ha vissuto.
    </span>,
    "In veste di mentor HAT avrai davanti artisti con problemi e soluzioni uniche, il tuo compito sarà fornire loro suggerimenti e consigli che lo motivino. Diventerai a tutti gli effetti la guida, anche se per un breve periodo, degli artisti con cui verrai a contatto.",
    <span>
      Come puoi prepararti al meglio per essere un mentor HAT?
      <br />
      Ricorda bene questo: una mentorship non è una consulenza. Non dovrai dare
      delle soluzioni precise agli artisti, ma guidarli tra i loro dubbi.
      Consigliare una scelta o un’altra, ma non vendere un servizio oppure
      offrire loro la soluzione completa. Gli artisti verranno da te per avere
      un supporto e dei consigli, creare una connessione sarà la parte più
      grande delle tue mentorship!
    </span>,
    <span>
      Come funzioneranno le mentorship?
      <br />
      Le call che farai con gli artisti avranno una durata di 30 minuti e si
      terranno su Google Meet. Ti verrà fornito un link per accedere alle call
      di mentorship che gli artisti prenoteranno. Per partecipare a HDH ti
      vengono richieste minimo 2 ore di disponibilità a settimana, che
      equivalgono a 4 call con gli artisti. Alla fine di ogni call verrà data la
      possibilità di lasciare una valutazione sia a te che all’artista, queste
      valutazioni saranno poi visibili a tutte le persone all’interno dell’app.
    </span>,
    <span>
      Come puoi essere d’aiuto al 100% a un artista?
      <br /> Trovando il giusto match. Grazie a una tua breve descrizione, ai
      generi musicali più affini e ai tuoi social media saremo in grado di
      consigliare il tuo profilo ad artisti in linea con te. Più affinità ci
      sarà, più sarà facile aiutarli!
    </span>,
  ];
  const graficheImgs = [guitarImg, graphic1Img, graphic2Img];
  const titoli = [
    "Informazioni personali",
    "Esperienza lavorativa",
    "Fatti riconoscere con una foto!",
    "Quando sei disponibile per le call?",
    "Presentati con un breve testo.",
  ];

  const { isDarkMode, isMobile } = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [genere, setGenere] = useState();
  const [city, setCity] = useState();
  const [dataNascita, setDataNascita] = useState();
  const [telefono, setTelefono] = useState("");
  const [categoria, setCategoria] = useState();
  const [sottoCategoria, setSottoCategoria] = useState();
  const [aziendeLavorato, setAziendeLavorato] = useState([""]);
  const [totAziendeLavor, setTotAziendeLavor] = useState(1);
  const [anniEsp, setAnniEsp] = useState();

  const [profilePicPrev, setProfilePicPrev] = useState(null);
  const [copertinaPicPrev, setCopertinaPicPrev] = useState(null);

  const [cv, setCv] = useState(null);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [selectedDisp, setSelectedDisp] = useState();

  const [descrizione, setDescrizione] = useState("");
  const [generi, setGeneri] = useState();

  const [linkSocial, setLinkSocial] = useState("");
  const [socialSelected, setSocialSelected] = useState();

  useEffect(() => {
    if (auth.currentUser) onLoad();
  }, []);

  const handleInput = (setValue, val) => {
    setValue(val);
  };

  const errorInputs = (error) => {
    showErrorNotification(error);
  };

  async function onLoad() {
    const newMentorRef = doc(db, "mentors", auth.currentUser.uid);
    const userDoc = await getDoc(newMentorRef);
    if (userDoc.exists()) {
      setNome(userDoc.data()["nome"] ?? "");
      setCognome(userDoc.data()["cognome"] ?? "");
      setTelefono(userDoc.data()["telefono"] ?? "");
      setAnniEsp(
        anniNelSottoreOption.find(
          (c) => c.value === userDoc.data()["anni_esperienza"]
        )
      );
      setCity(citiesOptions.find((c) => c.value === userDoc.data()["city"]));
      setDataNascita(
        yearOptions.find((c) => c.value === userDoc.data()["annoNascita"])
      );
      setGenere(
        generePersonaOptions.find(
          (c) => c.value === userDoc.data()["genere_persona"]
        )
      );

      setCategoria(
        BaseData.categorieOptions.find(
          (c) => c.value === userDoc.data()["categoria"]
        )
      );
      setSottoCategoria(
        (
          BaseData.sottoCategorieOptions[userDoc.data()["categoria"]] ?? []
        ).filter((c) =>
          (userDoc.data()["sotto_categoria"] ?? []).includes(c.value)
        )
      );
      setAziendeLavorato(userDoc.data()["azienda"] ?? [""]);
      setTotAziendeLavor((userDoc.data()["azienda"] ?? [""]).length);

      setProfilePicPrev(userDoc.data()["profilePicLink"]);
      setCopertinaPicPrev(userDoc.data()["copertinaPicLink"]);

      setIsSubmitted(userDoc.data()["isSubmitted"] ?? false);

      setSelectedDisp(
        Object.fromEntries(Object.entries(userDoc.data()["disp"] ?? {}).sort())
      );

      setDescrizione(userDoc.data()["desc"]);
      setGeneri(
        BaseData.generiOptions.filter((c) =>
          (userDoc.data()["generi"] ?? []).includes(c.value)
        )
      );
      setLinkSocial(userDoc.data()["linkSocial"] ?? "");

      console.log("dd");
      if (userDoc.data()["isSubmitted"] !== false) {
        if (
          userDoc.data()["nome"] &&
          userDoc.data()["cognome"] &&
          userDoc.data()["city"] &&
          userDoc.data()["annoNascita"] &&
          userDoc.data()["genere_persona"]
        )
          setStep(1);
        if (
          userDoc.data()["categoria"] &&
          userDoc.data()["sotto_categoria"] &&
          userDoc.data()["anni_esperienza"]
        )
          setStep(2);
        if (
          userDoc.data()["profilePicLink"] &&
          userDoc.data()["copertinaPicLink"]
        )
          setStep(3);
        if (userDoc.data()["disp"]) setStep(4);
        if (userDoc.data()["desc"] && userDoc.data()["generi"]) setStep(5);
      }
    }

    setIsLoading(false);
  }

  const submitInfo = async () => {
    const newMentorRef = doc(db, "mentors", auth.currentUser.uid);
    let newData = {};
    try {
      if (step == 0) {
        newData = {
          nome: nome,
          cognome: cognome,
          city: city.value,
          annoNascita: dataNascita.value,
          genere_persona: genere.value,
          telefono: telefono,
        };
      } else if (step == 1) {
        newData = {
          categoria: categoria.value,
          sotto_categoria: sottoCategoria.map((c) => c.value),
          azienda: aziendeLavorato,
          anni_esperienza: anniEsp.value,
        };
        if (
          cv &&
          !(typeof cv === "string" && cv.includes("https://firebase"))
        ) {
          const storageRefCV = ref(
            storage,
            "mentors/" + auth.currentUser.uid + "/files/cv"
          );
          const cvBlobCopertina = await fetch(cv).then((response) =>
            response.blob()
          );
          await uploadBytes(storageRefCV, cvBlobCopertina);
          const cvUrl = await getDownloadURL(storageRefCV);
          newData["cvLink"] = cvUrl;
        }
      } else if (step == 2) {
        let profUrl, copUrl;
        if (
          !(
            typeof profilePicPrev === "string" &&
            profilePicPrev.includes("https://firebase")
          )
        ) {
          const storageRefProfile = ref(
            storage,
            "mentors/" + auth.currentUser.uid + "/images/profilePic"
          );
          const imageBlobProfile = await fetch(profilePicPrev).then(
            (response) => response.blob()
          );
          await uploadBytes(storageRefProfile, imageBlobProfile);
          profUrl = await getDownloadURL(storageRefProfile);
          newData["profilePicLink"] = profUrl;
        }
        if (
          copertinaPicPrev &&
          !(
            typeof copertinaPicPrev === "string" &&
            copertinaPicPrev.includes("https://firebase")
          )
        ) {
          const storageRefCopertina = ref(
            storage,
            "mentors/" + auth.currentUser.uid + "/images/copertina"
          );
          const imageBlobCopertina = await fetch(copertinaPicPrev).then(
            (response) => response.blob()
          );
          await uploadBytes(storageRefCopertina, imageBlobCopertina);
          copUrl = await getDownloadURL(storageRefCopertina);
          newData["copertinaPicLink"] = copUrl;
        }
      } else if (step == 3) {
        newData = {
          disp: selectedDisp,
        };
      } else if (step == 4) {
        newData = {
          generi: generi.map((c) => c.value),
          desc: descrizione,
          linkSocial: linkSocial,
        };
      }
      console.log(newData);
      if (newData) await setDoc(newMentorRef, newData, { merge: true });
    } catch (e) {
      console.log(e);
      indietro();
      showErrorNotification("Errore nel caricare i dati");
    }
  };

  const inviaVerifca = async () => {
    try {
      console.log(auth.currentUser.uid);
      const newMentorRef = doc(db, "mentors", auth.currentUser.uid);
      await updateDoc(newMentorRef, { isSubmitted: true });
      setIsSubmitted(true);
    } catch (e) {
      showErrorNotification("Errore");
      console.log(e);
    }
  };

  const avanti = () => {
    const urlRegex = /^(https?:\/\/)?([\w-]+\.)*[\w-]+(:\d+)?(\/\S*)?$/;
    if (step == 0) {
      if (nome.length < 3) {
        errorInputs("Nome troppo corto");
        return;
      }
      if (cognome.length < 3) {
        errorInputs("Cognome troppo corto");
        return;
      }
      if (telefono.length < 5) {
        errorInputs("Inserisci un numero di telefono");
        return;
      }
      if (!city) {
        errorInputs("Seleziona una città");
        return;
      }
      if (!dataNascita) {
        errorInputs("Inserisci il tuo anno di nascita");
        return;
      }
      if (!genere) {
        errorInputs("Seleziona il tuo genere");
        return;
      }
    }

    if (step == 1) {
      if (!categoria) {
        errorInputs("Seleziona una categoria");
        return;
      }
      if (!sottoCategoria || sottoCategoria.length < 1) {
        errorInputs("Seleziona almeno una sottocategoria");
        return;
      }
      if (anniEsp == null) {
        errorInputs("Inserisci anni esperienxa");
        return;
      }
    }
    if (step == 2) {
      if (!profilePicPrev) {
        errorInputs("Inserisci un'immagine profilo");
        return;
      }
      // if(!copertinaPicPrev) { errorInputs("Inserisci un'immagine di copertina"); return;}
    }
    if (step == 3) {
      if (CalcolaDurataTotaleOreDisp(selectedDisp) < 2) {
        errorInputs("Seleziona almeno 2 ore");
        return;
      }
    }
    if (step == 4) {
      console.log(linkSocial);
      if (descrizione.length < 500) {
        errorInputs("Scrivi una descrizione di almeno 500 caratteri");
        return;
      }
      if (!generi || generi.length < 1) {
        errorInputs("Seleziona almeno un genere");
        return;
      }
      if (!linkSocial || !urlRegex.test(linkSocial)) {
        errorInputs("Inserisci un link social");
        return;
      }
    }
    setStep((s) => s + 1);
    submitInfo();
  };
  const indietro = () => {
    setStep((s) => s - 1);
  };
  const onImageChangeProfile = (file) => {
    if (file) {
      setProfilePicPrev(URL.createObjectURL(file));
    } else {
      showErrorNotification("Nessun file selezionato");
    }
  };
  const onImageChangeCopertina = (file) => {
    if (file) {
      setCopertinaPicPrev(URL.createObjectURL(file));
    } else {
      showErrorNotification("Nessun file selezionato");
    }
  };
  const onCvChange = (file) => {
    if (file) {
      setCv(URL.createObjectURL(file));
    } else {
      showErrorNotification("Nessun file selezionato");
    }
  };

  if (isLoading) {
    return (
      <RColumn verticalAlignment="center" height={"100vh"}>
        <div
          style={{
            borderRadius: "14px",
            width: isMobile ? "80%" : "700px",
            padding: "16px 20px",
            backgroundColor: isDarkMode
              ? ColorPalette.containerColorDark
              : ColorPalette.containerColorLight,
          }}
        >
          <Space height="200px" />
          <Title2 align={"center"}>Carico...</Title2>
          <Space height="200px" />
        </div>
      </RColumn>
    );
  }
  if (step == totSteps || isSubmitted === true) {
    return (
      <>
        <Space height="120px" />
        <RColumn
          padding={isMobile ? "0px 12px" : ""}
          horizontalAlign="center"
          width={isMobile ? "" : "100%"}
        >
          <div
            style={{
              borderRadius: "14px",
              width: isMobile ? "" : "700px",
              padding: "16px 20px",
              backgroundColor: isDarkMode
                ? ColorPalette.containerColorDark
                : ColorPalette.containerColorLight,
            }}
          >
            {!isSubmitted && (
              <>
                <Title2 align={"center"}>Ce l’hai quasi fatta!</Title2>
                <Space />
                <RText align={"center"}>
                  Controlla tutte le informazioni prima di mandarci la tua
                  candidatura
                </RText>
                <Space height="32px" />
              </>
            )}
            <div style={{ position: "relative" }}>
              <img
                src={
                  copertinaPicPrev ??
                  "https://mentor.hatmusic.it/banners/banner1.jpg"
                }
                width={"100%"}
                height={"100px"}
                style={{ objectFit: "cover" }}
              />

              <img
                src={profilePicPrev}
                width={"90px"}
                height={"90px"}
                style={{
                  objectFit: "cover",
                  position: "absolute",
                  left: "10px",
                  bottom: "-20px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <Space height="50px" />
            <RRow verticalAlignment="center" horizontalAlign="space-between">
              <RRow>
                <Title2>{nome}</Title2>
                <SpaceHorizontal width="12px" />
                <div
                  style={{
                    backgroundColor: "#6bab6f71",
                    padding: "4px 6px",
                    borderRadius: "6px",
                  }}
                >
                  <RText size="16px" color={"white"}>
                    {categoria.label}
                  </RText>
                </div>
              </RRow>
              {!isMobile && (
                <RGrid horizontalAlign="end" width={"55%"}>
                  {generi.map((element, index) => (
                    <div key={"q" + index} style={{ marginLeft: "8px" }}>
                      <RText size="16px">{element.label}</RText>
                      <SpaceHorizontal />
                    </div>
                  ))}
                </RGrid>
              )}
            </RRow>
            <Space height="12px" />
            <RRow>
              <RText>Esperto nel settore da </RText>
              <SpaceHorizontal />
              <Title2 size="19px">
                {anniEsp.value !== 11 ? anniEsp.value : "10+"}{" "}
                {anniEsp.value === 1 ? "anno" : "anni"}
              </Title2>
            </RRow>
            <Space height="12px" />
            {isMobile && (
              <RGrid>
                {generi.map((element, index) => (
                  <div key={"q" + index} style={{ marginRight: "8px" }}>
                    <RText size="16px">{element.label}</RText>
                    <SpaceHorizontal />
                  </div>
                ))}
              </RGrid>
            )}
            <Space height="12px" />
            <RRow>
              <RText>
                <span style={{ marginRight: "8px" }}>💼</span>
              </RText>
              {sottoCategoria.map((element, index) => (
                <div key={"d" + index} style={{ marginRight: "8px" }}>
                  <RText>{element.label}</RText>
                </div>
              ))}
            </RRow>
            <Space height="12px" />
            <RText>
              <span style={{ marginRight: "8px" }}>📍</span>
              {city.value}
            </RText>
            <Space height="12px" />
            <RText>
              <span style={{ marginRight: "8px" }}>📅 </span>
              {dataNascita.value}
            </RText>
            <Space height="12px" />
            {linkSocial && linkSocial.length > 0 && (
              <RText>
                <span style={{ marginRight: "8px" }}>🧭 </span>
                {linkSocial.includes("://")
                  ? linkSocial.split("://")[1]
                  : linkSocial}
              </RText>
            )}
            <Space height="12px" />
            <RRow verticalAlignment="start">
              <RText>📁</RText>
              <SpaceHorizontal />
              <RColumn horizontalAlign="start">
                {aziendeLavorato.map((azienda, index) => (
                  <RText key={"azienda" + index} fontWeight="bold">
                    {azienda}
                  </RText>
                ))}
              </RColumn>
            </RRow>
            <Space />
            {Object.entries(selectedDisp).map(([key, value]) => (
              <RRow key={key} verticalAlignment="start" padding={"6px 0px"}>
                <div style={{ width: "80px" }}>
                  <RText>{value.giorno}</RText>
                </div>
                <SpaceHorizontal />
                <RColumn verticalAlignment="start" horizontalAlign="start">
                  {value.orari.map((orario, index) => (
                    <RText key={"or" + index}>
                      {orario.start} - {orario.end}
                    </RText>
                  ))}
                </RColumn>
              </RRow>
            ))}
            <Space />
            <RText size="16px">{descrizione}</RText>
            <Space height="50px" />
            {!isSubmitted && (
              <RRow horizontalAlign="space-evenly">
                <div onClick={indietro} style={{ cursor: "pointer" }}>
                  <RText>Indietro</RText>
                </div>
                <PrimaryBtn onClick={inviaVerifca}>
                  Invia per la verifica
                </PrimaryBtn>
              </RRow>
            )}
            {isSubmitted && (
              <>
                <Title1 align={"center"}>Ben fatto!</Title1>
                <Space />
                <Space />
                <RText align={"center"}>
                  La tua candidatura verrà valutata dal nostro team nei prossimi
                  giorni.
                </RText>
                <Space height="8px" />
                <RText align={"center"}>
                  Ti contatteremo sulla mail che ci hai fornito, quindi tienila
                  d’occhio 👀
                </RText>
                <Space />
              </>
            )}
          </div>
        </RColumn>
      </>
    );
  }

  return (
    <>
      <RColumn
        padding={isMobile ? "0px 12px" : ""}
        horizontalAlign="center"
        width={isMobile ? "" : "100%"}
      >
        <Space height="130px" />
        <div
          style={{
            borderRadius: "14px",
            width: isMobile ? "auto" : "600px",
            padding: "16px 20px",
            backgroundColor: isDarkMode
              ? ColorPalette.containerColorDark
              : ColorPalette.containerColorLight,
          }}
        >
          <RColumn>
            <RRow horizontalAlign="space-between">
              <div
                style={{
                  marginRight: "10px",
                  height: "2px",
                  width: isMobile ? "50px" : "210px",
                  backgroundColor: isDarkMode
                    ? ColorPalette.textSecondaryDark
                    : ColorPalette.textSecondaryDark,
                }}
              ></div>
              <Title2>{Math.round((step / totSteps) * 100)}%</Title2>
              <div
                style={{
                  marginLeft: "10px",
                  height: "2px",
                  width: isMobile ? "50px" : "210px",
                  backgroundColor: isDarkMode
                    ? ColorPalette.textSecondaryDark
                    : ColorPalette.textSecondaryDark,
                }}
              ></div>
            </RRow>
            {/* {step != 3 && <img src={graficheImgs[step]} width="200px"/>} */}
            <Space />
            <Title2>{titoli[step]}</Title2>
            <Space />
            {step === 0 && (
              <>
                <InputField
                  placeHolder="Nome"
                  autoComplete="given-name"
                  setValue={(e) => handleInput(setNome, e)}
                  value={nome}
                  firstUpperCase
                />
                <InputField
                  placeHolder="Cognome"
                  autoComplete="family-name"
                  setValue={(e) => handleInput(setCognome, e)}
                  value={cognome}
                  firstUpperCase
                />
                <DropDownMenu
                  width={isMobile ? window.innerWidth - 60 : "600px"}
                  options={citiesOptions}
                  placeHolder={"Provincia"}
                  selectedValue={city}
                  onChange={setCity}
                />
                <DropDownMenu
                  width={isMobile ? window.innerWidth - 60 : "600px"}
                  options={yearOptions}
                  placeHolder={"Anno di nascita"}
                  selectedValue={dataNascita}
                  onChange={setDataNascita}
                />
                <DropDownMenu
                  width={isMobile ? window.innerWidth - 60 : "600px"}
                  options={generePersonaOptions}
                  placeHolder={"Genere"}
                  selectedValue={genere}
                  onChange={setGenere}
                />
                <InputField
                  placeHolder="Telefono"
                  autoComplete="tel"
                  setValue={(e) => handleInput(setTelefono, e)}
                  value={telefono}
                />
              </>
            )}
            {step === 1 && (
              <>
                <RColumn horizontalAlign="start">
                  <DropDownMenu
                    width={isMobile ? window.innerWidth - 60 : "600px"}
                    options={BaseData.categorieOptions}
                    placeHolder={"Categoria"}
                    selectedValue={categoria}
                    onChange={(e) => {
                      setCategoria(e);
                      setSottoCategoria(null);
                    }}
                  />
                  <DropDownMenu
                    width={isMobile ? window.innerWidth - 60 : "600px"}
                    siDisabled={!categoria}
                    isMulti={true}
                    options={BaseData.sottoCategorieOptions[categoria?.value]}
                    placeHolder={"Sotto categoria"}
                    selectedValue={sottoCategoria}
                    onChange={setSottoCategoria}
                  />
                  <DropDownMenu
                    width={isMobile ? window.innerWidth - 60 : "600px"}
                    options={anniNelSottoreOption}
                    placeHolder={"Da quanti anni lavori nel settore musicale?"}
                    selectedValue={anniEsp}
                    onChange={setAnniEsp}
                  />

                  <RRow width={"100%"} verticalAlignment="end">
                    <RColumn width={"100%"}>
                      {Array.from({ length: totAziendeLavor }, (_, index) => (
                        <InputField
                          key={index}
                          placeHolder={"Azienda in cui lavori o hai lavorato"}
                          setValue={(e) =>
                            setAziendeLavorato((prev) => {
                              const newText = [...prev];
                              newText[index] = e;
                              return newText;
                            })
                          }
                          value={aziendeLavorato[index]}
                        />
                      ))}
                    </RColumn>
                    <RColumn>
                      {totAziendeLavor > 1 && (
                        <div
                          onClick={() =>
                            setTotAziendeLavor((p) => {
                              aziendeLavorato.pop();
                              return p - 1;
                            })
                          }
                          style={{ padding: "0px 10px", cursor: "pointer" }}
                        >
                          <Title2>-</Title2>
                        </div>
                      )}
                      <div
                        onClick={() => setTotAziendeLavor((p) => p + 1)}
                        style={{
                          padding:
                            totAziendeLavor > 1 ? "0px 10px" : "14px 10px",
                          cursor: "pointer",
                        }}
                      >
                        <Title2>+</Title2>
                      </div>
                    </RColumn>
                  </RRow>
                  <Space />
                  <RRow>
                    <RText>Carica CV</RText>
                    <SpaceHorizontal />
                    <FileUploadButton
                      accept=".pdf, .jpg"
                      onFileChange={onCvChange}
                    />
                    <SpaceHorizontal />
                    {cv && <RText size="14px">Caricato</RText>}
                  </RRow>
                  <Space height="8px" />
                  <RText size="14px">
                    Se lo carichi, valuteremo in modo piu' completo la tua
                    candidatura
                  </RText>
                </RColumn>
              </>
            )}
            {step == 2 && (
              <>
                <RRow horizontalAlign="space-between" width={"100%"}>
                  <RColumn horizontalAlign="start">
                    <RText>Carica immagine profilo</RText>
                    <Space height="8px" />
                    <FileUploadButton onFileChange={onImageChangeProfile} />
                  </RColumn>
                  <RColumn>
                    <img src={profilePicPrev} width="90px"></img>
                  </RColumn>
                </RRow>
                <Space />
                <RRow horizontalAlign="space-between" width={"100%"}>
                  <RColumn horizontalAlign="start">
                    <RText>Carica immagine copertina</RText>
                    <Space height="8px" />
                    <FileUploadButton onFileChange={onImageChangeCopertina} />
                  </RColumn>
                  <RColumn>
                    <img src={copertinaPicPrev} width="90px"></img>
                  </RColumn>
                </RRow>
              </>
            )}
            {step == 3 && (
              <>
                <Space />
                <SelezionaGiorniOrai
                  selectDispCallback={(v) => setSelectedDisp(v)}
                  initialSelectedDisp={selectedDisp}
                />
              </>
            )}
            {step == 4 && (
              <>
                <Space />
                <InputFieldMultiline
                  maxChar={10000}
                  placeHolder={
                    "come ti racconteresti agli artisti che stanno per scoprirti?"
                  }
                  value={descrizione}
                  setValue={(e) => handleInput(setDescrizione, e)}
                />
                <Space height="8px" />
                <DropDownMenu
                  width={isMobile ? window.innerWidth - 80 : "600px"}
                  isMulti={true}
                  selectedValue={generi}
                  onChange={setGeneri}
                  placeHolder={
                    "Seleziona i generi con i quali sei specializzato"
                  }
                  options={BaseData.generiOptions}
                />
                {socialSelected == null && (
                  <>
                    <Space />
                    <div
                      style={{
                        backgroundColor: isDarkMode
                          ? ColorPalette.containerSecondaryColorDark
                          : ColorPalette.containerSecondaryColorLight,
                        borderRadius: "12px",
                        width: "100%",
                      }}
                    >
                      <RRow
                        width={"100%"}
                        horizontalAlign="center"
                        padding={"8px"}
                      >
                        <RText
                          size="16px"
                          color={
                            isDarkMode
                              ? ColorPalette.textSecondaryDark
                              : ColorPalette.textSecondaryLight
                          }
                        >
                          Social che più ti rappresenta
                        </RText>
                        <SpaceHorizontal />
                        <RRow>
                          {socialLogos.map((img, index) => (
                            <div
                              onClick={() => setSocialSelected(index)}
                              key={index}
                              style={{ cursor: "pointer", margin: "0px 8px" }}
                            >
                              <img src={img} width={"22px"} />
                            </div>
                          ))}
                        </RRow>
                      </RRow>
                    </div>
                  </>
                )}
                {socialSelected != null && (
                  <RRow width={"100%"}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setSocialSelected(null)}
                    >
                      <Title2 size="15px">X</Title2>
                    </div>
                    <SpaceHorizontal />
                    <img src={socialLogos[socialSelected]} width={"22px"} />
                    <SpaceHorizontal />
                    <InputField
                      placeHolder={"Link Social"}
                      value={linkSocial}
                      setValue={(e) => handleInput(setLinkSocial, e)}
                    />
                  </RRow>
                )}
              </>
            )}
            <Space />
            <RText size="16px">{frasiSotto[step]}</RText>
            <Space />
            <Space />
            <RRow horizontalAlign="space-evenly" width="100%">
              {step > 0 && (
                <div onClick={indietro} style={{ cursor: "pointer" }}>
                  <RText>Precedente</RText>
                </div>
              )}
              <PrimaryBtn onClick={avanti}>Avanti</PrimaryBtn>
            </RRow>
          </RColumn>
        </div>
      </RColumn>
    </>
  );
};

export default OnboardingPage;
