import { italyCities } from "./cities";
import logoInsta from "../assets/instagram.png";
import logoTikTok from "../assets/tik-tok.png";
import logoYT from "../assets/youtube.png";
import logoSpoty from "../assets/spotify.png";
import soundCloud from "../assets/soundcloud.png";
import linkedIn from "../assets/linkedin.png";

export const socialLogos = [
  logoInsta,
  logoTikTok,
  logoYT,
  logoSpoty,
  soundCloud,
  linkedIn,
];
export const socialIDToImg = {
  spoty: logoSpoty,
};
export const prefLinkSocial = {
  spoty: "https://open.spotify.com/artist/",
};
export class ColorPalette {
  static primaryColor = "#967BA8";
  static accentOrange = "#D35F2F";
  static accentGreen = "#77B06D";

  static bgColorDark = "#0D0D0D";
  static containerColorDark = "#1A1A1A";
  static containerSecondaryColorDark = "#292929";
  static textColorDark = "white";
  static textSecondaryDark = "#CBCACA";

  static bgColorLight = "#F4F4F4";
  static containerColorLight = "#E3E3E3";
  static containerSecondaryColorLight = "#F4F4F4";
  static textColorLight = "#000000";
  static textSecondaryLight = "#121212";
}

export class BaseData {
  static categorieOptions = [];
  static sottoCategorieOptions = {};
  static competenzeOptions = [];
  static generiOptions = [];
  static typeCallLabel = {};
}

export const citiesOptions = italyCities.map((c) => ({
  label: c["nome"],
  value: c["nome"],
}));
export const yearOptions = Array.from(
  { length: new Date().getFullYear() - 1950 + 1 },
  (_, index) => ({ label: 1950 + index, value: 1950 + index })
);

export const anniNelSottoreOption = Array.from({ length: 12 }, (_, index) => ({
  label: index === 11 ? "Più di 10" : index,
  value: index,
}));

export const generePersonaOptions = [
  { value: "uomo", label: "Uomo" },
  { value: "donna", label: "Donna" },
  { value: "altro", label: "Altro" },
];
